/* eslint-disable multiline-ternary */
import * as React from "react";
import { useState, useEffect } from "react";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Circle from "@mui/icons-material/Circle";
import CircleOutlined from "@mui/icons-material/CircleOutlined";
import axios from "axios";
import useFetchData from "../../../../api/FetchApi";
import configData from "../../../../config.json";
import Analyze from "../../../../pages/Analyze";
import { TextField } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Select from "@mui/material/Select";
import DialogTitle from "@mui/material/DialogTitle";
import { setMyAnswers } from "../../../../redux/analyseSlice";
import { setTranslationSlice } from "../../../../redux/analyseSlice";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import finnish from "../../../../languages/finnish.json";
import english from "../../../../languages/english.json";
import { useNavigate } from "react-router-dom";
import DiginetBanner from "../../../../static/diginet_banner_logo.jpg";
import DiginetArrows from "../../../../static/img-arrows.svg";
import ConvertQuotesInObject from "../../../../utils/jsonParser";

export default function BasicRating() {
  const [open, setOpen] = useState(false);
  const [answerValue, setAnswerValue] = useState({});
  const [dataForAnalyze, setDataForAnalyze] = useState({});
  const [count, setCount] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [code, setCode] = useState(null);
  const [translation, setTranslation] = useState("fi_FIN");
  const [url, setUrl] = useState("");
  const [fieldMemory, setFieldMemory] = useState({});
  const [progress, setProgress] = useState(0);
  const [fetchedData, setFetchedData] = useState({
    answerData: null,
    qGroups: null,
  });
  const navigate = useNavigate();

  let { FetchResult, loading, fetchError } = useFetchData(url, {
    keyname: "code",
    value: code !== null ? code : localStorage.getItem("surveyCode") !== "null" ? localStorage.getItem("surveyCode") : "",
    language: "lang",
    languageValue: translation,
  });

  /*function convertQuotesBack(originalString) {
    return originalString.replace(/&quote/g, '"');
  }*/

  /*if(FetchResult !== undefined && FetchResult.length > 0) {
    FetchResult = FetchResult.text();
  } */ 

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  useEffect(() => {
    
    const fetchData = async () => {
      setUrl(`${configData.SERVER_URL}/get/survey/`);
  
    const respAnswerData = await axios(
      `${
        configData.SERVER_URL
      }/get/survey/?code=${code !== null ? code : localStorage.getItem("surveyCode") !== "null" ? localStorage.getItem("surveyCode") : ""}&lang=${translation}&token=${localStorage.getItem(
        "token"
      )}`
    );
    if (respAnswerData.data && respAnswerData.data.result === "FAIL") {
      return alert("Oops, wrong code...");
    }
    const convertedRespData = ConvertQuotesInObject(respAnswerData);
    //console.log("converted respAnswers on: ", convertedRespData)
    const groupArray = await Object.values(
      //respAnswerData.data.data[0].data.pages
      convertedRespData.data.data[0].data.pages
    ).map((item) => {
      const returnable = Object.keys(item.groups).map((groupItem) => {
        return groupItem;
      });
      return returnable;
    });
    const parsedArray = [];
    if (groupArray.length > 0) {
      await groupArray.forEach((item) => {
        item.forEach((subItem) => {
          parsedArray.push(subItem);
        });
      });
    }
    const respQgroups = await axios(
      `${
        configData.SERVER_URL
      }/get/qgroupinfo/?gid=${parsedArray.toString()}&lang=${translation}&token=${localStorage.getItem(
        "token"
      )}`
    ).catch((err) => {
      console.log("error from group fetch: ", err);
      return null;
    });
    //console.log("resp groups: ", respQgroups.data)
    const convertedResp = ConvertQuotesInObject(respQgroups);
    const parsedGroups =
      (await respQgroups) !== null
      //? await Object.values(respQgroups.data.data).map((item) => {
        ? await Object.values(convertedResp.data.data).map((item) => {
            return {
              description: item.description,
              id: item.id,
              title: item.title,
              showinference: item.showinference,
            };
          })
        : null;
    if ((await parsedGroups) !== null) {
      //respQgroups.data.data = parsedGroups;
      convertedResp.data.data = parsedGroups;
    }
   
    setFetchedData({
      //answerData: respAnswerData.data,
      //qGroups: respQgroups !== null ? respQgroups.data.data : null,
      answerData: convertedRespData.data,
      qGroups: respQgroups !== null ? convertedResp.data.data : null,
    });
    }
    (localStorage.getItem("role") !== "ADMIN" &&
    localStorage.getItem("role") !== "SUPER") &&
    fetchData();
  }, [])

  const dispatch = useDispatch();
  const reduxLanguage = useSelector((state) => state.language.value.language);
  let useLanguage = reduxLanguage === "finnish" ? finnish : english;
  //console.log("FETCHDATA", FetchResult)

  let validationSchema = Yup.object().shape({
    question: Yup.string().required("Question title is required"),
    rating: Yup.number().required().positive().integer(),
  });

  //custom style for making some titles smaller when on mobile
  const Root = styled("h4")(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "3vw",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5vw",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5vw",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "13px",
    },
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const style = {
    width: "100%",
    maxWidth: "100%",
    bgcolor: "background.paper",
  };

  const StyledRating = styled(Rating)({});
  const sendData = async () => {
    //console.log("sent data....")
    localStorage.setItem("formID", FetchResult[0].form);
    const sendArr = Object.keys(answerValue).map((item) => {
      let returnData = {};
      if (answerValue[item][1] === "default") {
        returnData = { qid: item, ans: answerValue[item][0], type: "default" };
      } else {
        returnData = { qid: item, ans: answerValue[item][2], type: "other" };
      }
      return returnData;
    });
    try {
      const sendPacket = JSON.stringify({ data: sendArr });
      const bodyFormData = new FormData();
      bodyFormData.append("data", sendPacket.replace(/"/g, "'"));
      
      //console.log("lähetysviesti on: ", sendPacket.replace(/"/g, "'"));
      await axios({
        method: "post",
        url: `${configData.SERVER_URL}/post/answer/`,
        data: bodyFormData,
        params: { token: localStorage.getItem("token") },
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "FRACTIONAL",
      });
    } catch (error) {
      console.error("error while sending data... ", error);
    }
    localStorage.setItem("myAnswers", sendArr);
    localStorage.setItem("myanalyzedata", dataForAnalyze);
    await fetchedData.qGroups.forEach((item, index) => {
      if (item.showinference === false) {
        delete dataForAnalyze[index];
      }
    });
    //console.log("DATAFORANALYZE: ", dataForAnalyze)
    const convertedMyAnswers = ConvertQuotesInObject(dataForAnalyze);
    //dispatch(setMyAnswers(dataForAnalyze));
    dispatch(setMyAnswers(convertedMyAnswers));
    setCompleted(true);
  };

  const handleClose = async () => {
    setOpen(false);
    setUrl(`${configData.SERVER_URL}/get/survey/`);
    
    const respAnswerData = await axios(
      `${
        configData.SERVER_URL
      }/get/survey/?code=${code !== null ? code : localStorage.getItem("surveyCode") !== "null" ? localStorage.getItem("surveyCode") : ""}&lang=${translation}&token=${localStorage.getItem(
        "token"
      )}`
    );
    if (respAnswerData.data && respAnswerData.data.result === "FAIL") {
      return alert("Oops, wrong code...");
    }
    const convertedRespAnswerData = ConvertQuotesInObject(respAnswerData);
    //console.log("respAnswers on: ", respAnswerData)
    const groupArray = await Object.values(
      //respAnswerData.data.data[0].data.pages
      convertedRespAnswerData.data.data[0].data.pages
    ).map((item) => {
      const returnable = Object.keys(item.groups).map((groupItem) => {
        return groupItem;
      });
      return returnable;
    });
    const parsedArray = [];
    if (groupArray.length > 0) {
      await groupArray.forEach((item) => {
        item.forEach((subItem) => {
          parsedArray.push(subItem);
        });
      });
    }
    const respQgroups = await axios(
      `${
        configData.SERVER_URL
      }/get/qgroupinfo/?gid=${parsedArray.toString()}&lang=${translation}&token=${localStorage.getItem(
        "token"
      )}`
    ).catch((err) => {
      console.log("error from group fetch: ", err);
      return null;
    });
    const convertedRespQgroups = ConvertQuotesInObject(respQgroups);
    //console.log("resp groups: ", respQgroups.data)
    const parsedGroups =
      (await respQgroups) !== null
      //? await Object.values(respQgroups.data.data).map((item) => {
        ? await Object.values(convertedRespQgroups.data.data).map((item) => {
            return {
              description: item.description,
              id: item.id,
              title: item.title,
              showinference: item.showinference,
            };
          })
        : null;
    if ((await parsedGroups) !== null) {
      //respQgroups.data.data = parsedGroups;
      convertedRespQgroups.data.data = parsedGroups;
    }
    setFetchedData({
      //answerData: respAnswerData.data,
      //qGroups: respQgroups !== null ? respQgroups.data.data : null,
      answerData: convertedRespAnswerData.data,
      qGroups: respQgroups !== null ? convertedRespQgroups.data.data : null,
    });
  };

  const handleExit = () => {
    localStorage.clear();
    setOpen(false);
    navigate("/login");
  };

  const handleFieldChange = (event, groupItem2, pageIndex) => {
    if (event.target.value !== "") {
      const prevValues = dataForAnalyze[pageIndex]
        ? dataForAnalyze[pageIndex].values
        : {};
      setDataForAnalyze({
        ...dataForAnalyze,
        [pageIndex]: {
          ...dataForAnalyze[pageIndex],
          title:
            fetchedData.qGroups !== null
              ? fetchedData.qGroups[pageIndex].title
              : null,
          group: groupItem2.group,
          values: {
            ...prevValues,
            [groupItem2.question]: event.target.value,
          },
        },
      });
    } else {
      const prevAnalyzeData = dataForAnalyze;
      delete prevAnalyzeData[pageIndex].values[groupItem2.question];
      setDataForAnalyze(prevAnalyzeData);
    }
    //}
    setAnswerValue({
      ...answerValue,
      [groupItem2.question]: [event.target.value, "default"],
    });
  };

  const handleRadioChange = (event, groupItem2, isText, pageIndex) => {
    const prevValues = dataForAnalyze[pageIndex]
      ? dataForAnalyze[pageIndex].values
      : {};
    setDataForAnalyze({
      ...dataForAnalyze,
      [pageIndex]: {
        ...dataForAnalyze[pageIndex],
        title:
          fetchedData.qGroups !== null
            ? fetchedData.qGroups[pageIndex].title
            : null,
        group: groupItem2.group,
        values: {
          ...prevValues,
          [groupItem2.question]: event.target.value,
        },
      },
    });

    let value = "";
    let type = "";
    if (isNaN(event.target.value)) {
      type = "other";
    } else {
      type = "default";
    }
    if (type === "default") {
      value = [event.target.value, type, ""];
    } else {
      if (isText !== null) {
        value = [answerValue[groupItem2.question][0], type, event.target.value];

        setFieldMemory((prevState) => ({
          ...prevState,
          [groupItem2.question]: {
            ...prevState[groupItem2.question],
            [answerValue[groupItem2.question][0]]: event.target.value,
          },
        }));
      } else {
        //tämä tulee väärässä ajassa joten vähän kusee?
        if (fieldMemory[groupItem2.question]) {
          value = [
            event.target.value,
            type,
            fieldMemory[groupItem2.question][event.target.value],
          ];
        } else {
          value = [event.target.value, type, ""];
        }
      }
    }

    setAnswerValue((prevState) => ({
      ...prevState,
      [groupItem2.question]: value,
    }));
  };

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const handleTranslationChange = (e) => {
    setTranslation(e.target.value);
    dispatch(setTranslationSlice(e.target.value));
  };

  const TranslationElement = () => (
    <Box sx={{ minWidth: 120 }} style={{ marginTop: "50px" }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">
          {useLanguage.Answering.language}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={translation}
          label="Translation"
          onChange={handleTranslationChange}
        >
          <MenuItem value={"en_ENG"}>English</MenuItem>
          <MenuItem value={"fi_FIN"}>Finnish</MenuItem>
          {/*<MenuItem value={"de_DEU"}>Germany</MenuItem>
          <MenuItem value={"hu_HUN"}>Hungary</MenuItem>
          <MenuItem value={"es_ESP"}>Spanish</MenuItem>*/}
        </Select>
      </FormControl>
    </Box>
  );

  return (
    <div style={{ minHeight: "89.25vh" }}>
      {/*<Dialog
        open={
          /*open &&
          localStorage.getItem("role") !== "ADMIN" &&
          localStorage.getItem("role") !== "SUPER"
          false
        }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {useLanguage.Answering.insertCodeTitle}
        </DialogTitle>
        <DialogContent>
          <TranslationElement />
          <TextField
            disabled={translation === "" ? true : false}
            onChange={handleCodeChange}
            style={{ marginTop: "5%" }}
            value={code !== null ? code : localStorage.getItem("surveyCode") !== "null" ? localStorage.getItem("surveyCode") : ""}
            label={localStorage.getItem("surveyCode") !== "null" ? localStorage.getItem("surveyCode") : useLanguage.Answering.insertCodeLabel}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={translation === "" ? true : false}
            onClick={handleExit}
          >
            {useLanguage.Answering.exit}
          </Button>
          <Button
            disabled={translation === "" ? true : false}
            onClick={handleClose}
          >
            {useLanguage.Answering.insertCode}
          </Button>
        </DialogActions>
      </Dialog>*/}
      {(localStorage.getItem("role") === "ADMIN" ||
        localStorage.getItem("role") === "SUPER") && (
        <div
          style={{
            display: "flex",
            //justifyContent: "center",
            margin: "auto",
            maxWidth: "200px",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <TranslationElement />
          <TextField
            style={{ marginTop: "5%" }}
            label="Insert Survey code"
            variant="outlined"
            disabled={translation === "" ? true : false}
            onChange={handleCodeChange}
          />
          <Button
            variant="contained"
            onClick={handleClose}
            style={{ marginTop: "5%" }}
            disabled={translation === "" ? true : false}
          >
            OK
          </Button>
        </div>
      )}
      {open !== true && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading && (localStorage.getItem("role") !== "ADMIN" &&
    localStorage.getItem("role") !== "SUPER") && <CircularProgress style={{ marginTop: "5%" }} />}
          {fetchError && (
            <h2 style={{ marginTop: "10%" }}>{useLanguage.Error.fetchError}</h2>
          )}
          {!loading && fetchError === null && (
            <div style={{ display: "flex", alignItems: "center" }}>
              {completed ? (
                <div
                  style={{
                    width: "80vw",
                    display: "flex",
                    flexFlow: "column",
                    alignItems: "stretch",
                    marginTop: "5%",
                  }}
                >
                  <h1>{useLanguage.Answering.thanks}</h1>
                  <Analyze />
                </div>
              ) : (
                <Paper
                  style={{
                    width: "80vw",
                    marginTop: "5%",
                    marginBottom: "5%",
                    maxWidth: "1200px",
                  }}
                >
                  {count === 0 &&
                  <div className="titleContainer">
                  <div className="titleBackgroundImage">
                      
                  </div>
                  <Paper className="TitlePaperStyle">
                          {/*<h1 style={{justifySelf: "center", fontSize: "clamp(1.5rem, 5vw, 3rem)"}}>{FetchResult[0].survey_title}</h1>*/}
                          <h1 className="textStyle">{FetchResult[0].survey_title}</h1>
                          <img src={DiginetArrows} alt="arrows pointing down" style={{maxHeight: "1.5vh"}} />
                      </Paper>
                  </div>
                  }
                   {/*<img src={DiginetBanner} alt="Diginet banner" style={{width: "100%", position: "absolute"}}></img>*/}
                  <div >
                  <div style={{width: "100%", marginTop: "5%",
                  display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Box sx={{ width: '80%' }}>
                        <LinearProgressWithLabel value={progress} />
                    </Box>
                  </div>
                  
                  {FetchResult !== undefined && FetchResult.length > 0 ? (
                    <div
                      style={{
                        marginTop: "5%",
                        marginLeft: "10%",
                        marginRight: "10%",
                      }}
                    >
                      {count === 0 && <h3>{FetchResult[0].form_title}</h3>}
                      <div
                        style={{
                          // display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        {FetchResult.length > 0
                          ? Object.values(FetchResult[0].data.pages).map(
                              (item, pageIndex) => (
                                <div
                                  style={{
                                    display: "flex",
                                    maxWidth: "1250px",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: "100%",
                                    flexDirection: "column",
                                  }}
                                  key={`pageDiv${pageIndex}`}
                                >
                                  {pageIndex === count ? (
                                    <div>
                                      {Object.values(item.groups).map(
                                        (groupItem, groupItemIndex) => (
                                          <div
                                            style={{
                                              marginTop: "5%",
                                              backGroundColor: "#201751",
                                            }}
                                            key={`paper${groupItemIndex}`}
                                          >
                                            <h2>
                                              {fetchedData.qGroups &&
                                                fetchedData.qGroups[pageIndex]
                                                  .title}
                                            </h2>
                                            <h3>
                                              {fetchedData.qGroups &&
                                                fetchedData.qGroups[pageIndex]
                                                  .description}
                                            </h3>
                                            <List
                                              sx={style}
                                              component="nav"
                                              aria-label="mailbox folders"
                                              key={`List${groupItemIndex}`}
                                            >
                                              <div
                                                key={`MainDiv${groupItemIndex}`}
                                              >
                                                {Object.values(groupItem).map(
                                                  (
                                                    groupItem2,
                                                    groupItem2Index
                                                  ) => (
                                                    <div
                                                      key={`groupItem2Div${groupItem2Index}`}
                                                    >
                                                      <ListItem
                                                        key={`ListItem${groupItem2Index}`}
                                                        button
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                        }}
                                                      >
                                                        <Grid
                                                          container
                                                          key={`parentGrid${groupItem2Index}`}
                                                          style={{
                                                            display: "flex",
                                                            justifyContent:
                                                              "space-between",
                                                            alignItems:
                                                              "center",

                                                            flexWrap: "wrap",
                                                          }}
                                                        >
                                                          <Grid
                                                            key={`gridItem1${groupItem2Index}`}
                                                            item
                                                            xs={12}
                                                            sm={6}
                                                          >
                                                            <Typography
                                                              component="legend"
                                                              key={`typography1${groupItem2Index}`}
                                                            >
                                                              <h4>
                                                                {
                                                                  groupItem2.title
                                                                }
                                                              </h4>
                                                            </Typography>
                                                            {groupItem2.option_type ===
                                                              "RADIO" && (
                                                              <FormControl
                                                                key={`radioFormControl${groupItem2Index}`}
                                                                style={{
                                                                  marginTop:
                                                                    "5%",
                                                                  marginLeft:
                                                                    "5%",
                                                                }}
                                                              >
                                                                {groupItem2.hasOwnProperty(
                                                                  "questionOptions"
                                                                ) ? (
                                                                  <RadioGroup
                                                                    key={`radioGroup${groupItem2Index}`}
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    defaultValue=""
                                                                    name="radio-buttons-group"
                                                                    value={
                                                                      groupItem2.question in
                                                                      answerValue
                                                                        ? answerValue[
                                                                            groupItem2
                                                                              .question
                                                                          ][0]
                                                                        : ""
                                                                    }
                                                                    onChange={(
                                                                      event
                                                                    ) => {
                                                                      if (
                                                                        event
                                                                          .target
                                                                          .value !==
                                                                        ""
                                                                      ) {
                                                                        handleRadioChange(
                                                                          event,
                                                                          groupItem2,
                                                                          null,
                                                                          pageIndex
                                                                        );
                                                                      }
                                                                    }}
                                                                  >
                                                                    {groupItem2.questionOptions.map(
                                                                      (
                                                                        questionOptionItem,
                                                                        questionOptionIndex
                                                                      ) => (
                                                                        <div
                                                                          key={`radioDiv${questionOptionIndex}`}
                                                                        >
                                                                          {questionOptionItem.optionType ===
                                                                          "OPTION" ? (
                                                                            <FormControlLabel
                                                                              key={`radioLabel${groupItem2Index}`}
                                                                              value={
                                                                                questionOptionIndex
                                                                              }
                                                                              control={
                                                                                <Radio />
                                                                              }
                                                                              label={
                                                                                questionOptionItem.title
                                                                              }
                                                                            />
                                                                          ) : (
                                                                            <FormControlLabel
                                                                              value={`other${questionOptionIndex}`}
                                                                              control={
                                                                                <Radio name="gender" />
                                                                              }
                                                                              label={
                                                                                <TextField
                                                                                  id="standard-bare"
                                                                                  placeholder={
                                                                                    questionOptionItem.title
                                                                                  }
                                                                                  margin="normal"
                                                                                  disabled={
                                                                                    answerValue[
                                                                                      groupItem2
                                                                                        .question
                                                                                    ]
                                                                                      ? answerValue[
                                                                                          groupItem2
                                                                                            .question
                                                                                        ][0] !==
                                                                                        `other${questionOptionIndex}`
                                                                                      : true
                                                                                  }
                                                                                  onChange={(
                                                                                    event
                                                                                  ) => {
                                                                                    if (
                                                                                      event
                                                                                        .target
                                                                                        .value !==
                                                                                      ""
                                                                                    ) {
                                                                                      handleRadioChange(
                                                                                        event,
                                                                                        groupItem2,
                                                                                        "text",
                                                                                        pageIndex
                                                                                      );
                                                                                    }
                                                                                  }}
                                                                                />
                                                                              }
                                                                            />
                                                                          )}
                                                                        </div>
                                                                      )
                                                                    )}
                                                                  </RadioGroup>
                                                                ) : null}
                                                              </FormControl>
                                                            )}
                                                          </Grid>
                                                          {groupItem2.option_type ===
                                                            "SELECT" && (
                                                            <Grid
                                                              key={`selectGrid${groupItem2Index}`}
                                                              item
                                                              xs={12}
                                                              sm={6}
                                                            >
                                                              <Grid
                                                                container
                                                                justifyContent="center"
                                                                spacing={2}
                                                              >
                                                                <Grid
                                                                  item
                                                                  xs={6}
                                                                  sm={6}
                                                                  md={6}
                                                                  lg={6}
                                                                  xl={3}
                                                                  order={{
                                                                    xs: 1,
                                                                    sm: 1,
                                                                    md: 1,
                                                                  }}
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "flex-start",
                                                                    alignItems:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <Root>
                                                                    <h4
                                                                      key={`selecth4${groupItem2Index}`}
                                                                      style={{
                                                                        textAlign:
                                                                          "center",
                                                                      }}
                                                                    >
                                                                      {
                                                                        groupItem2.scale_min_title
                                                                      }
                                                                    </h4>
                                                                  </Root>
                                                                </Grid>
                                                                <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                                  lg={12}
                                                                  xl={6}
                                                                  order={{
                                                                    xs: 2,
                                                                    sm: 2,
                                                                    md: 2,
                                                                    lg: 2,
                                                                    xl: 1,
                                                                  }}
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "center",
                                                                    alignItems:
                                                                      "center",
                                                                    paddingTop:
                                                                      "0px",
                                                                  }}
                                                                >
                                                                  <StyledRating
                                                                    {...register(
                                                                      "rating"
                                                                    )}
                                                                    error={
                                                                      errors.rating
                                                                        ? true
                                                                        : false
                                                                    }
                                                                    key={`TextField1${groupItem2Index}`}
                                                                    icon={
                                                                      <Circle fontSize="inherit" />
                                                                    }
                                                                    emptyIcon={
                                                                      <CircleOutlined fontSize="inherit" />
                                                                    }
                                                                    name={`qid${groupItem2.question}`}
                                                                    highlightSelectedOnly
                                                                    value={
                                                                      groupItem2.question in
                                                                      answerValue
                                                                        ? answerValue[
                                                                            groupItem2
                                                                              .question
                                                                          ][0]
                                                                        : 0
                                                                    }
                                                                    max={
                                                                      groupItem2.scale_max
                                                                    }
                                                                    size="large"
                                                                    onChange={(
                                                                      event,
                                                                      newValue
                                                                    ) => {
                                                                      let groupData =
                                                                        null;
                                                                      if (
                                                                        answerValue[
                                                                          groupItem2
                                                                            .question
                                                                        ]
                                                                      ) {
                                                                        groupData =
                                                                          answerValue[
                                                                            groupItem2
                                                                              .question
                                                                          ][0];
                                                                      }
                                                                      if (
                                                                        newValue !==
                                                                        null
                                                                      ) {
                                                                        groupData =
                                                                          newValue;
                                                                      }

                                                                      const prevValues =
                                                                        dataForAnalyze[
                                                                          pageIndex
                                                                        ]
                                                                          ? dataForAnalyze[
                                                                              pageIndex
                                                                            ]
                                                                              .values
                                                                          : {};

                                                                      setDataForAnalyze(
                                                                        {
                                                                          ...dataForAnalyze,
                                                                          [pageIndex]:
                                                                            {
                                                                              ...dataForAnalyze[
                                                                                pageIndex
                                                                              ],
                                                                              title:
                                                                                fetchedData.qGroups !==
                                                                                null
                                                                                  ? fetchedData
                                                                                      .qGroups[
                                                                                      pageIndex
                                                                                    ]
                                                                                      .title
                                                                                  : null,
                                                                              group:
                                                                                groupItem2.group,
                                                                              values:
                                                                                {
                                                                                  ...prevValues,
                                                                                  [groupItem2.question]:
                                                                                    event
                                                                                      .target
                                                                                      .value,
                                                                                },
                                                                            },
                                                                        }
                                                                      );

                                                                      setAnswerValue(
                                                                        (
                                                                          prevState
                                                                        ) => ({
                                                                          ...prevState,
                                                                          [groupItem2.question]:
                                                                            [
                                                                              groupData,
                                                                              "default",
                                                                            ],
                                                                        })
                                                                      );
                                                                    }}
                                                                  />
                                                                </Grid>
                                                                <Grid
                                                                  item
                                                                  xs={6}
                                                                  sm={6}
                                                                  md={6}
                                                                  lg={6}
                                                                  xl={3}
                                                                  order={{
                                                                    xs: 1,
                                                                    sm: 1,
                                                                    md: 1,
                                                                  }}
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "flex-end",
                                                                    alignItems:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <Root>
                                                                    <h4
                                                                      style={{
                                                                        textAlign:
                                                                          "center",
                                                                      }}
                                                                    >
                                                                      {
                                                                        groupItem2.scale_max_title
                                                                      }
                                                                    </h4>
                                                                  </Root>
                                                                </Grid>
                                                              </Grid>
                                                            </Grid>
                                                          )}
                                                          {groupItem2.option_type ===
                                                            "FIELD" && (
                                                            <TextField
                                                              style={{
                                                                width: "100%",
                                                                marginTop: "2%",
                                                              }}
                                                              id="ok"
                                                              label={
                                                                useLanguage
                                                                  .Answering
                                                                  .fieldLabel
                                                              }
                                                              variant="outlined"
                                                              value={
                                                                answerValue[
                                                                  groupItem2
                                                                    .question
                                                                ]
                                                                  ? answerValue[
                                                                      groupItem2
                                                                        .question
                                                                    ][0]
                                                                  : ""
                                                              }
                                                              multiline
                                                              onChange={(e) =>
                                                                handleFieldChange(
                                                                  e,
                                                                  groupItem2,
                                                                  pageIndex
                                                                )
                                                              }
                                                            />
                                                          )}
                                                          <br />
                                                        </Grid>
                                                      </ListItem>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            </List>
                                          </div>
                                        )
                                      )}
                                      {FetchResult.length > 0 ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent:
                                              count > 0
                                                ? "space-between"
                                                : "flex-end",
                                            marginBottom: "5%",
                                          }}
                                        >
                                          {count > 0 ? (
                                            <Button
                                              variant="contained"
                                              style={{ marginTop: "5%" }}
                                              onClick={() => {
                                                window.scrollTo(0, 0);

                                                
                                               
                                                
                                                setProgress(((count-1) / Number(Object.keys(FetchResult[0].data.pages)
                                                .length - 1)) * 100)
                                                setCount(count - 1);
                                              }}
                                            >
                                              {useLanguage.Answering.backButton}
                                            </Button>
                                          ) : null}
                                          {count + 1 !==
                                          Object.keys(FetchResult[0].data.pages)
                                            .length ? (
                                            <Button
                                              variant="contained"
                                              style={{ marginTop: "5%" }}
                                              onClick={() => {
                                                window.scrollTo(0, 0);

                                                const fetchValues =
                                                  Object.values(
                                                    FetchResult[0].data.pages[
                                                      count + 1
                                                    ].groups
                                                  ).map((item, index) => item);
                                                const parsedValues =
                                                  fetchValues[0].map((item) =>
                                                    item.question.toString()
                                                  );

                                                //Checking if there is same amount of answered values than what is found in the current page's group from fetchdata
                                                //TÄMÄ ON HETKEN AIKAA POIS, TAKAISIN KUN TEHDÄÄN FEATURET LOPPUUN...

                                                /*if (
                                                  Object.keys(answerValue)
                                                    .length > 0 &&
                                                  dataForAnalyze[pageIndex] &&
                                                  dataForAnalyze[pageIndex]
                                                    .title ===
                                                    fetchedData.qGroups[
                                                      pageIndex
                                                    ].title &&
                                                  parsedValues.length ===
                                                    Object.keys(
                                                      dataForAnalyze[pageIndex]
                                                        .values
                                                    ).length
                                                ) {*/
                                               
                                                  
                                                  
                                                  //setProgress((1 / 3
                                                  setProgress(((count+1) / Number(Object.keys(FetchResult[0].data.pages)
                                                  .length - 1)) * 100)
                                                  // * 100))
                                                  //console.log("pages: ", Object.keys(FetchResult[0].data.pages))
                                                  //console.log("lasku: ", (count+1), " / ", Object.keys(FetchResult[0].data.pages).length -1)
                                                  setCount(count + 1);
                                                  handleSubmit();
                                                /*} else {
                                                  alert(
                                                    "Vastaa kaikkiin kohtiin, kiitos"
                                                  );
                                                }*/
                                              }}
                                            >
                                              {useLanguage.Answering.nextButton}
                                            </Button>
                                          ) : null}
                                          {count + 1 ===
                                          Object.keys(FetchResult[0].data.pages)
                                            .length ? (
                                            <Button
                                              variant="contained"
                                              style={{ marginTop: "5%" }}
                                              onClick={() => {
                                                const fetchValues =
                                                  Object.values(
                                                    FetchResult[0].data.pages[
                                                      count + 1
                                                    ].groups
                                                  ).map((item, index) => item);
                                                const parsedValues =
                                                  fetchValues[0].map((item) =>
                                                    item.question.toString()
                                                  );

                                                /*if (
                                                  Object.keys(dataForAnalyze)
                                                    .length > 0 &&
                                                  dataForAnalyze[pageIndex]
                                                    .title ===
                                                    fetchedData.qGroups[
                                                      pageIndex
                                                    ].title &&
                                                  parsedValues.length ===
                                                    Object.keys(
                                                      dataForAnalyze[pageIndex]
                                                        .values
                                                    ).length
                                                ) {*/
                                                //console.log("dataforana", dataForAnalyze)

                                                //TÄMÄ ON HETKEN AIKAA POIS, TAKAISIN KUN TEHDÄÄN FEATURET LOPPUUN...

                                                  //if(Object.keys(dataForAnalyze).length > 0 && dataForAnalyze.hasOwnProperty([pageIndex]) && parsedValues.length === Object.keys(dataForAnalyze[pageIndex].values).length){

                                                  //window.scrollTo(0, 0);
                                                  sendData();
                                                //} 
                                                /*else {
                                                  alert(
                                                    "Vastaa kaikkiin kohtiin, kiitos"
                                                  );
                                                }*/
                                              }}
                                            >
                                              {useLanguage.Answering.submit}
                                            </Button>
                                          ) : null}
                                        </div>
                                      ) : null}
                                    </div>
                                  ) : null}
                                </div>
                              )
                            )
                          : null}
                      </div>
                      
                    </div>
                    
                  ) : null}
                  </div>
                </Paper>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
