import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Seamklogo from '../../../../static/seamkLogo.png'
import DiginetLogo from '../../../../static/DigiNet_pink.png'
import VaasaLogo from '../../../../static/vaasan-yliopisto-logo.png'
import EuLogo from '../../../../static/eu_logo_new.png'
import LiittoLogo from '../../../../static/EPLiitto_logo_vaaka_vari.jpg'
import { Paper } from '@mui/material';

export default function SimpleBottomNavigation() {
  const [value, setValue] = React.useState(0);

  return (
    <Box sx={{ position: 'static', bottom: 0, left: 0,
    right: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", flexWrap: "wrap"}} elevation={3}>
      
        <img src={DiginetLogo} style={{height: "4vh", marginBottom: "5%"}}/>
        <img src={VaasaLogo} style={{height: "8vh", marginBottom: "5%"}}/>
        <img src={Seamklogo} style={{height: "5vh", marginBottom: "5%"}}/>
        <img src={EuLogo} style={{height: "4vh", marginBottom: "5%"}}/>
        <img src={LiittoLogo} style={{height: "5vh", marginBottom: "5%"}}/>
        
    </Box>
  );
}