import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Paper from "@mui/material/Paper";
import Rating from "@mui/material/Rating";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Circle from "@mui/icons-material/Circle";
import CircleOutlined from "@mui/icons-material/CircleOutlined";
import { TextField } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useSelector } from "react-redux";
import finnish from "../../../../../languages/finnish.json";
import english from "../../../../../languages/english.json";

const SurveySneakPeek = (props) => {
  const { onClose, open } = props;

  const StyledRating = styled(Rating)({
    /* '& .MuiRating-iconFilled': {
          color: '#201751',
        },
        '& .MuiRating-iconHover': {
          color: '#201751',
        }, */
  });
  const reduxLanguage = useSelector((state) => state.language.value.language);
  let useLanguage = reduxLanguage === "finnish" ? finnish : english;
  const Root = styled("h4")(({ theme }) => ({
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "3vw",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2vw",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5vw",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "15px",
    },
  }));
  const style = {
    width: "100%",
    maxWidth: "100%",
    bgcolor: "background.paper",
  };
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullScreen>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Close
              </Typography>
            </Toolbar>
          </AppBar>
          <Paper>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "80vw" }}>
                  {props.form !== undefined && props.form.length > 0 ? (
                    <div style={{ marginTop: "5%" }}>
                      <h1>{props.title}</h1>
                      <h3>{props.form[0].title}</h3>
                      <div
                        style={{
                          justifyContent: "space-around",
                        }}
                      >
                        {props.form.length > 0
                          ? props.form[0].sciquestionGroups.map(
                              (item, pageIndex) => (
                                <div
                                  style={{
                                    display: "flex",
                                    maxWidth: "1250px",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: "100%",
                                    flexDirection: "column",
                                  }}
                                  key={`pageDiv${pageIndex}`}
                                >
                                  <h2>{item.title && item.title.toString()}</h2>
                                  <h3>
                                    {item.description &&
                                      item.description.toString()}
                                  </h3>

                                  <div>
                                    {item.questions.map(
                                      (groupItem, groupItemIndex) => (
                                        <div
                                          sx={{ border: "1px solid #201751" }}
                                          style={{
                                            backGroundColor: "#201751",
                                          }}
                                          key={`paper${groupItemIndex}`}
                                        >
                                          <List
                                            sx={style}
                                            component="nav"
                                            aria-label="mailbox folders"
                                            key={`List${groupItemIndex}`}
                                          >
                                            <div
                                              key={`MainDiv${groupItemIndex}`}
                                            >
                                              <div
                                                key={`groupItem2Div${groupItemIndex}`}
                                              >
                                                <ListItem
                                                  key={`ListItem${groupItemIndex}`}
                                                  button
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <Grid
                                                    container
                                                    key={`parentGrid${groupItemIndex}`}
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      alignItems: "center",

                                                      flexWrap: "wrap",
                                                    }}
                                                  >
                                                    <Grid
                                                      key={`gridItem1${groupItemIndex}`}
                                                      item
                                                      xs={12}
                                                      sm={6}
                                                    >
                                                      <Typography
                                                        component="legend"
                                                        key={`typography1${groupItemIndex}`}
                                                      >
                                                        {groupItem.title}
                                                      </Typography>
                                                      {groupItem.optionSetting
                                                        .optionType ===
                                                        "RADIO" && (
                                                        <FormControl
                                                          key={`radioFormControl${groupItemIndex}`}
                                                          style={{
                                                            marginLeft: "5%",
                                                          }}
                                                        >
                                                          {groupItem.hasOwnProperty(
                                                            "questionOptions"
                                                          ) ? (
                                                            <RadioGroup
                                                              key={`radioGroup${groupItemIndex}`}
                                                              aria-labelledby="demo-radio-buttons-group-label"
                                                              defaultValue=""
                                                              name="radio-buttons-group"
                                                            >
                                                              {groupItem.questionOptions.map(
                                                                (
                                                                  questionOptionItem,
                                                                  questionOptionIndex
                                                                ) => (
                                                                  <div
                                                                    key={`radioDiv${questionOptionIndex}`}
                                                                  >
                                                                    {questionOptionItem.optionType ===
                                                                    "OPTION" ? (
                                                                      <FormControlLabel
                                                                        key={`radioLabel${groupItemIndex}`}
                                                                        value={
                                                                          questionOptionIndex
                                                                        }
                                                                        control={
                                                                          <Radio />
                                                                        }
                                                                        label={
                                                                          questionOptionItem.title
                                                                        }
                                                                      />
                                                                    ) : (
                                                                      <FormControlLabel
                                                                        control={
                                                                          <Radio name="gender" />
                                                                        }
                                                                        label={
                                                                          <TextField
                                                                            id="standard-bare"
                                                                            placeholder={
                                                                              questionOptionItem.title
                                                                            }
                                                                            margin="normal"
                                                                          />
                                                                        }
                                                                      />
                                                                    )}
                                                                  </div>
                                                                )
                                                              )}
                                                            </RadioGroup>
                                                          ) : null}
                                                        </FormControl>
                                                      )}
                                                    </Grid>
                                                    {groupItem.optionSetting
                                                      .optionType ===
                                                      "SELECT" && (
                                                      <Grid
                                                        key={`selectGrid${groupItemIndex}`}
                                                        item
                                                        xs
                                                      >
                                                        <Grid
                                                          container
                                                          justifyContent="center"
                                                          spacing={2}
                                                        >
                                                          <Grid
                                                            item
                                                            xs={6}
                                                            sm={6}
                                                            md={6}
                                                            lg={3}
                                                            order={{
                                                              xs: 1,
                                                              sm: 1,
                                                              md: 1,
                                                            }}
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "flex-start",
                                                              alignItems:
                                                                "center",
                                                            }}
                                                          >
                                                            <Root>
                                                              <h4
                                                                key={`selecth4${groupItemIndex}`}
                                                                style={{
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {
                                                                  groupItem
                                                                    .optionSetting
                                                                    .scaleMinLabel
                                                                }
                                                              </h4>
                                                            </Root>
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            xs={6}
                                                            order={{
                                                              xs: 2,
                                                              sm: 2,
                                                              md: 2,
                                                              lg: 1,
                                                            }}
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "center",
                                                              alignItems:
                                                                "center",
                                                              paddingTop: "0px",
                                                            }}
                                                          >
                                                            <StyledRating
                                                              key={`TextField1${groupItemIndex}`}
                                                              icon={
                                                                <Circle fontSize="inherit" />
                                                              }
                                                              emptyIcon={
                                                                <CircleOutlined fontSize="inherit" />
                                                              }
                                                              name={`qid${groupItem.title}`}
                                                              max={Number(
                                                                groupItem
                                                                  .optionSetting
                                                                  .scaleMax
                                                              )}
                                                              size="large"
                                                            />
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            xs={6}
                                                            sm={6}
                                                            md={6}
                                                            lg={3}
                                                            order={{
                                                              xs: 1,
                                                              sm: 1,
                                                              md: 1,
                                                            }}
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "flex-end",
                                                              alignItems:
                                                                "center",
                                                            }}
                                                          >
                                                            <Root>
                                                              <h4
                                                                style={{
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {
                                                                  groupItem
                                                                    .optionSetting
                                                                    .scaleMaxLabel
                                                                }
                                                              </h4>
                                                            </Root>
                                                          </Grid>
                                                        </Grid>
                                                        {/*</div>*/}
                                                      </Grid>
                                                    )}
                                                    {groupItem.optionSetting
                                                      .optionType ===
                                                      "FIELD" && (
                                                      <TextField
                                                        style={{
                                                          width: "100%",
                                                          marginTop: "2%",
                                                        }}
                                                        id="ok"
                                                        label={
                                                          useLanguage.Answering
                                                            .fieldLabel
                                                        }
                                                        variant="outlined"
                                                        multiline
                                                      />
                                                    )}
                                                    <br />
                                                  </Grid>
                                                </ListItem>
                                              </div>
                                            </div>
                                          </List>
                                        </div>
                                      )
                                    )}
                                    <Divider />
                                  </div>
                                </div>
                              )
                            )
                          : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Paper>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default SurveySneakPeek;
