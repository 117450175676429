import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Button } from "@mui/material";
import useFetchData from "../../../../../api/FetchApi";
import SurveyTranslation from "./SurveyTranslation";
import CreateAnalytics from "./CreateAnalytics";
import axios from "axios";
import configData from "../../../../../config.json";

const SurveyPreview = (props) => {
  const { onClose, open, id, setTemplateData, openAsTemplate, setMaxSteps, fetchData } =
    props;
  const [responseOpen, setResponseOpen] = useState(false);
  const [translationOpen, setTranslationOpen] = useState(false);
  const [surveyData, setSurveyData] = useState({});
  const [surveyDataSwitch, setSurveyDataSwitch] = useState(false);
  const [previousResponseData, setPreviousResponseData] = useState({});
  const { FetchResult } = useFetchData(props.url, {
    keyname: "id",
    value: props.id,
  });

  //console.log("eka fetchResult: ", FetchResult, "osoite: ", props.url)

  const fetchSurveyData = async () => {
    const surveyFetchData = await axios(
      `${
        configData.SERVER_URL
      }/get/translation/surveys/?id=${id}&token=${localStorage.getItem(
        "token"
      )}`
    ).catch((err) => console.log("ERROR from fetchin surveyData: ", err));
    setSurveyData(surveyFetchData.data);
    return await surveyFetchData;
  };

  if (open === true && surveyDataSwitch === false) {
    setSurveyDataSwitch(true);
    fetchSurveyData().then((res) => {
      setPreviousResponseData(res.data.data.fi_FIN.r);
    });
  }

  const handleClose = () => {
    onClose();
    setSurveyDataSwitch(false);
  };

  const handleResponseClick = () => {
    setResponseOpen(true);
  };
  const handleResponseClose = () => {
    setResponseOpen(false);
  };

  const handleTranslationClick = () => {
    setTranslationOpen(true);
  };
  const handleTranslationClose = () => {
    setTranslationOpen(false);
  };

  const setAsTemplate = async (templateType) => {
    //console.log("FETCHRESULT: ", FetchResult)
    const setGroupNames = async () => {
      const groupArray = await Object.values(FetchResult[0].data.pages).map(
        (item) => {
          const returnable = Object.keys(item.groups).map((groupItem) => {
            return groupItem;
          });
          return returnable;
        }
      );
      const parsedArray = [];
      if (groupArray.length > 0) {
        await groupArray.forEach((item) => {
          item.forEach((subItem) => {
            parsedArray.push(subItem);
          });
        });
      }
      const respQgroups = await axios(
        `${
          configData.SERVER_URL
        }/get/qgroupinfo/?gid=${parsedArray.toString()}&lang=fi_FIN&token=${localStorage.getItem(
          "token"
        )}`
      );
      return respQgroups.data.data;

      //setGroupData({ qGroups: respQgroups.data.data });
    };
    //console.log("FetchResult: ", FetchResult)
    await setGroupNames().then((res) => {
      //convertoidaan data sopivaan muotoon survey pohjaksi
      const convertedSciForm = Object.values(FetchResult[0].data.pages).map(
        (group, pageIndex) => {
          const questionData = group.groups[Object.keys(group.groups)[0]].map(
            (question) => {
              if(templateType === "uusi") {
                question.questionOptions.forEach(optionItem => {
                  delete optionItem.id
                })
                
              }
              const returnable = templateType === "uusi" ?
              {
                title: question.title,
                //refquestion: templateType === "seuranta" ? question.question : "",
                resultType: "FRACTIONAL",
                optionSetting: {
                  scaleMin: question.scale_min,
                  scaleMax: question.scale_max,
                  scaleMinLabel: question.scale_min_title,
                  scaleMaxLabel: question.scale_max_title,
                  optionType: question.option_type,
                },
                questionOptions: question.questionOptions,
              } : {
                title: question.title,
                refquestion: templateType === "seuranta" ? question.question : "",
                resultType: "FRACTIONAL",
                optionSetting: {
                  scaleMin: question.scale_min,
                  scaleMax: question.scale_max,
                  scaleMinLabel: question.scale_min_title,
                  scaleMaxLabel: question.scale_max_title,
                  optionType: question.option_type,
                },
                questionOptions: question.questionOptions,
              };
              return returnable
            }
          );
          return {
            page: pageIndex + 1,
            title: res[pageIndex].title,
            description: res[pageIndex].description,
            questions: questionData,
          };
          //return {
          //  title: group.groups[Object.keys(group.groups)[0]][0].title

          //}
        }
      );

      const convertedData = templateType === "uusi" ? {
        title: FetchResult[0].survey_title,
        //code: "",
        start: new Date(),
        end: new Date(),
        sciforms: [
          {
            title: FetchResult[0].form_title,
            sciquestionGroups: convertedSciForm,
          },
        ],
      } :
      {
        title: FetchResult[0].survey_title,
        //code: "",
        start: new Date(),
        end: new Date(),
        sciforms: [
          {
            title: FetchResult[0].form_title,
            refform:  templateType === "seuranta" || templateType === "jatko" ? FetchResult[0].form : "",
            sciquestionGroups: convertedSciForm,
          },
        ],
      }
      setMaxSteps(convertedSciForm.length);
      //console.log("converted was: ", convertedData);
      setTemplateData(convertedData);
      openAsTemplate();
      onClose();
    });
    //console.log("groups are: ", setGroupNames);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <SurveyTranslation
        data={FetchResult}
        open={translationOpen}
        fetchData={fetchData}
        onClose={handleTranslationClose}
      />
      <CreateAnalytics
        previousResponseData={previousResponseData}
        data={FetchResult}
        open={responseOpen}
        onClose={handleResponseClose}
        surveyData={surveyData}
        fetchData={fetchData}
        prevPropsUrl={props.url}
      />
      <DialogTitle>Survey Preview</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        
          {FetchResult &&
            FetchResult.map((item) => (
              <div>
                Link to survey: <a href={`${configData.CLIENT_URL}/login?code=${item.survey_code}`}>{`${configData.CLIENT_URL}/login?code=${item.survey_code}`}</a>
                <p>Survey: {item.survey}</p>
                <p>Survey code: {item.survey_code}</p>
                <p>Survey title: {item.survey_title}</p>
                <p>Form: {item.form}</p>
                <p>Form title: {item.form_title}</p>
                <p>Palautetekstit: </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginTop: "2",
                  }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleResponseClick()}
                  >
                    Tee palautetekstit
                  </Button>
                  <br />

                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleTranslationClick()}
                  >
                    Tee käännökset
                  </Button>
                  <br />

                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => setAsTemplate("uusi")}
                  >
                    Avaa uudeksi pohjaksi
                  </Button>
                  <br />
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => setAsTemplate("jatko")}
                  >
                    Avaa jatkokyselyksi
                  </Button>
                  <br />
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => setAsTemplate("seuranta")}
                  >
                    Avaa seurantakyselyksi
                  </Button>
                  <br />
                  <Button
                    component="button"
                    variant="contained"
                    color="success"
                    href={`${
                      configData.SERVER_URL
                    }/get/report/csv/?id=${id}&token=${localStorage.getItem(
                      "token"
                    )}`}
                    target="_blank"
                    rel="noopener"
                  >
                    Tallenna CSV-muodossa
                  </Button>
                </div>
              </div>
            ))}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default SurveyPreview;
