import React from "react";
import { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
//import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import Button from "@mui/material/Button";
import { jsPDF } from "jspdf";
import { useSelector } from "react-redux";
import { toPng } from "html-to-image";
import { Paper } from "@mui/material/";
import CircularProgress from "@mui/material/CircularProgress";
//import ReactPDF from '@react-pdf/renderer';
import useFetchData from "../api/FetchApi";
import configData from "../config.json";
import ConvertQuotesInObject from "../utils/jsonParser";
import english from '../languages/english.json';
import finnish from '../languages/finnish.json';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Analyysi vastauksista",
    },
  },
};

const labels = [
  "Kysymysryhmä 1",
  "Kysymysryhmä 2",
  "Kysymysryhmä 3",
  "Kysymysryhmä 4",
  "Kysymysryhmä 5",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Yleinen keskiarvo",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Omat vastaukset",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

async function creatPdf(doc, elements) {
  let top = 20;
  const padding = 10;

  for (let i = 0; i < elements.length; i++) {
    const el = elements.item(i);
    const imgData = await toPng(el);

    let elHeight = el.offsetHeight;
    let elWidth = el.offsetWidth;

    const pageWidth = doc.internal.pageSize.getWidth();

    if (elWidth > pageWidth) {
      const ratio = pageWidth / elWidth;
      elHeight = elHeight * ratio - padding;
      elWidth = elWidth * ratio - padding;
    }

    const pageHeight = doc.internal.pageSize.getHeight();

    if (top + elHeight > pageHeight) {
      doc.addPage();
      top = 20;
    }

    doc.addImage(imgData, "PNG", padding, top, elWidth, elHeight, `image${i}`);
    top += elHeight;
  }
}

export async function exportMultipleChartsToPdf() {
  const doc = new jsPDF("p", "px"); // (1)

  const elements = document.getElementsByClassName("custom-chart"); // (2)
  //console.log("elements: ", elements)

  await creatPdf(doc, elements); // (3-5)
  if (
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      navigator.userAgent.toLowerCase()
    )
  ) {
    window.open(doc.output("bloburl", { filename: `charts.pdf` }));
  } else {
    window.open(doc.output('bloburl', { filename: `charts.pdf` }));
    doc.save(`charts.pdf`);
  }
  //doc.save(`charts.pdf`); // (6)
}

function Analyze() {


  const [testState, setTestState] = useState([]);
  const [updateSwitch, setUpdateSwitch] = useState(true);
  const [pdfLoading, setPdfLoading] = useState(false);

  const reduxLanguage = useSelector((state) => state.language.value.language);
  const reduxTranslation = useSelector((state) => state.analyse.translation);

let useLanguage = reduxLanguage === "finnish" ? finnish : english;
  const { FetchResult, loading, fetchError } = useFetchData(

    `${configData.SERVER_URL}/get/analysis/form/`,
    { keyname: "id", value: localStorage.getItem("formID") }
  );

  const myAnswers = useSelector((state) => state.analyse.value);
  let fetchDataset = [];
  let answers = {};
  let average = {};

//console.log("myanswers: ", myAnswers)

//ANALYYSISSÄ KARSITAAN OMISTA VASTAUKSISA AVOIMET KYSYMYKSET

  const keys = Object.values(myAnswers).map((value) => {
    let count = 0;
    let length = 0;
    Object.values(value.values).forEach((item) => {
      if(!isNaN(Number(item))){
        count += Number(item);
        length += 1;
      }
      
    });
    
    average[value.group] = count / length;
    
   
    return { [value.group]: count };
  });
  keys.forEach((item) => {
    Object.keys(item).forEach((item2) => {
      answers[item2] = item[item2];
    });
  });
  if (FetchResult !== undefined) {
    fetchDataset = FetchResult.analyses.map((item, index) => {
      const aIndex = Object.keys(myAnswers).find((key) => Object.keys(myAnswers[key]).some((group) => myAnswers[key].group === item.group))
      const groupName = myAnswers[aIndex].title;
      const firstColor =
        item.percintiles[0].percintile >= answers[item.group]
          ? "#78aed3"
          : "#f2f2f2";
      const secondColor =
        item.percintiles[1].percintile >= answers[item.group] &&
        answers[item.group] > item.percintiles[0].percintile
          ? "#78aed3"
          : "#f2f2f2";
      const thirdColor =
        item.percintiles[2].percintile >= answers[item.group] &&
        answers[item.group] > item.percintiles[1].percintile
          ? "#78aed3"
          : "#f2f2f2";
      const fourthColor =
        item.percintiles[2].percintile < answers[item.group]
          ? "#78aed3"
          : "#f2f2f2";
      const firstTitle = `Equal or under ${item.percintiles[0].percintile}`;
      const secondTitle = `Equal or under ${item.percintiles[1].percintile}`;
      const thirdTitle = `Equal or under ${item.percintiles[2].percintile}`;
      const fourthTitle = `Above ${item.percintiles[2].percintile}`;

      return [
        {
          label: firstTitle,
          data: { [groupName]: 25 },
          backgroundColor: firstColor,
          borderWidth: 2,
          borderRadius: 5,
          borderSkipped: false,
          stack: index,
        },
        {
          label: secondTitle,
          data: { [groupName]: 25 },
          backgroundColor: secondColor,
          borderWidth: 2,
          borderRadius: 5,
          borderSkipped: false,
          stack: index,
        },
        {
          label: thirdTitle,
          data: { [groupName]: 25 },
          backgroundColor: thirdColor,
          borderWidth: 2,
          borderRadius: 5,
          borderSkipped: false,
          stack: index,
        },
        {
          label: fourthTitle,
          data: { [groupName]: 25 },
          backgroundColor: fourthColor,
          borderWidth: 2,
          borderRadius: 5,
          borderSkipped: false,
          stack: index,
        },
      ];
    });
  }

  const parsedDataset = [];
  if (fetchDataset.length > 0) {
    for (const item of fetchDataset) {
      for (const subitem of item) {
        parsedDataset.push(subitem);
      }
    }
  }


const fetchGroupData = async () => {
const tes = await Promise.all(Object.values(myAnswers).map(async (item, index) => {
    return fetch(`${configData.SERVER_URL}/get/response/?gid=${item.group}&score=${average[item.group]}&lang=${reduxTranslation}&token=${localStorage.getItem("token")}`)
    //.then((response) => (response.json())).then((convertedResponse) => ({id: item.group, title: item.title, data: convertedResponse.data}))
    .then((response) => (response.json())).then((jsonResponse) => ConvertQuotesInObject(jsonResponse)).then( convertedResponse => ({id: item.group, title: item.title, data: convertedResponse.data}))
}))
return tes
}

//console.log("myanswers: ", myAnswers)

if(updateSwitch === true && FetchResult !== undefined && Object.keys(FetchResult).length > 0 && FetchResult.analyses.length > 0){
  //console.log("testifunkkari ajettiin")
  fetchGroupData().then(response => 
    {
      //console.log("fetch groupin data: ", response)
      setUpdateSwitch(false)
      setTestState(response)
    })
}


  const data2 = {
    datasets: parsedDataset,
    datasets2: [
      {
        label: "under 25%",
        data: { "Group 1": 25 },
        backgroundColor: "#f2f2f2",
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
        stack: "Stack 1",
      },
      {
        data: { "Group 1": 25 },
        backgroundColor: "#78aed3",
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
        stack: "Stack 1",
      },
      {
        data: { "Group 1": 25 },
        backgroundColor: "#f2f2f2",
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
        stack: "Stack 1",
      },
      {
        data: { "Group 1": 25 },
        backgroundColor: "#f2f2f2",
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
        stack: "Stack 1",
      },
      {
        data: { "Group 2": 25 },
        backgroundColor: "#f2f2f2",
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
        stack: "Stack 2",
      },
      {
        data: { "Group 2": 25 },
        backgroundColor: "#f2f2f2",
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
        stack: "Stack 2",
      },
      {
        data: { "Group 2": 25 },
        backgroundColor: "#78aed3",
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
        stack: "Stack 2",
      },
      {
        data: { "Group 2": 25 },
        backgroundColor: "#f2f2f2",
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
        stack: "Stack 2",
      },
    ],
  }

  const config = {
    type: "bar",
    data: data2,
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
          base: 50,
        },
      },
    },
  };
  const config2 = {
    type: "bar",
    options: {
      animation: {
        duration: 0
    },
      plugins: {
        legend: {
          display: true,
        },
      },
      responsive: true,
     
    },
  };

  let labelos = [];

  const data6 = Object.values(myAnswers).map((item, index) => {
    labelos.push(item.title);
    return average[item.group];
  });

  const data7 = FetchResult ? FetchResult.analyses.map(item => item.average) : []
 
  const toimivadata = {
    labels: labelos,
    datasets: [
      {
        label: [useLanguage.Analytics.yourAverage],
        data: data6,
        fill: true,
        backgroundColor: "#78aed3",
        borderColor: "rgba(75,192,192,1)",
      },
      {
        label: [useLanguage.Analytics.globalAverage],
        data: data7,
        fill: false,
        borderColor: "#742774",
        backgroundColor: "rgba(255, 99, 132, 0.5)"
      },
    ],
  };

  /*const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    }
  });*/

  /*const makePDF = () => {
    ReactPDF.render(<MyDocument />, `${__dirname}/example.pdf`);
  }

  const MyDocument = () => (
    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>Section #1</Text>
      </View>
      <View style={styles.section}>
        <Text>Section #2</Text>
      </View>
    </Page>
  </Document>
  )*/

  return (
    <Paper style={{marginTop: "5%", marginBottom: "5%"}}>
      
      {loading && <p>loading...</p>}
      {!loading && (
        <div className="custom-chart" style={{display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "center"}}>
          
                {(testState.length > 0) &&
                <div style={{width: "100%", display: "flex", justifyContent: "flex-start"}}>
                <div style={{marginTop: "5%", marginLeft: "10%", marginRight: "10%", marginBottom: "5%"}} >
                  <h1>{useLanguage.Analytics.responseTitle}</h1>
                  {testState.map((item, index) => 
                      
                      <div>
                        { (item !== (undefined || null) && (Object.keys(item).length > 0 || item.id) && item.data !== undefined && Object.keys(item.data).length > 0) &&
                          <div ><h2>{item.title}:</h2> <h3>{item.data !== undefined ? item.data.value : ""}</h3></div>
                        } 
                        </div>
                    )} 
                      </div>
              
              </div>
                 
                }
                
          {/*} <div style={{marginLeft: "5%", marginRight: "5%", flexGrow: 1, maxWidth: "800px", display: "flex", 
           justifyContent: "space-between", flexDirection: "column"}}>
          <h2>
            {useLanguage.Analytics.percintileTitle}
          </h2>
          <h5>{useLanguage.Analytics.percintileInfo}</h5>
          <Bar  options={config.options} data={data2}/>
              </div>*/}
           <div style={{marginLeft: "5%", marginRight: "5%", flexGrow: 1, maxWidth: "800px", display: 
           "flex", justifyContent: "space-between", flexDirection: "column"}}>
          <h2>{useLanguage.Analytics.averageTitle}</h2>
          <Bar  options={config2.options} data={toimivadata} />
          </div>
        </div>
      )}
      {/*pdfLoading ? <CircularProgress /> :
      <Button style={{marginLeft: "2%", marginBottom: "5%", marginTop: "2%"}} variant="contained" 
      onClick={
        () => {
        //pdfLoadingButton();
        //exportMultipleChartsToPdf().then(() => setPdfLoading(false))
        //makePDF();
        }
      }>{useLanguage.Analytics.pdfExport}</Button>
    */}
    </Paper>
  );
}

export default React.memo(Analyze);
