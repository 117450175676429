import "./App.css";
import Main from "./pages/Main";
import Survey from "./pages/Surveys";
import Login from "./pages/Login";
import Register from "./pages/Register";
import UserManagement from "./pages/UserManagement";
import ResetPassword from "./pages/ResetPassword";
import React from "react";
import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";

const Page404 = () => <h1>404 Page not found...</h1>;

function RequireAuth({ children }) {
  let location = useLocation();
  if (!localStorage.getItem('authenticated') || localStorage.getItem('authenticated') === false 
  || (!localStorage.getItem('token'))){

    console.log("token not found... Redirecting to Login page -->")
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

const App = () => (
  // eslint-disable-next-line react/react-in-jsx-scope

    <BrowserRouter>
      <Routes>
        
        
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="resetpassword" element={<ResetPassword />} />
        <Route path="*" element={<Page404 />} />
        <Route
        path="/"
        element={
          <RequireAuth>
            <Main />
          </RequireAuth>
        }
      />
      <Route
        path="survey"
        element={
          <RequireAuth>
            <Survey />
          </RequireAuth>
        }
      />
        <Route
        path="/usermanagement"
        element={
          <RequireAuth>
            <UserManagement />
          </RequireAuth>
        }
      />
      </Routes>
    </BrowserRouter>

);

export default App;
