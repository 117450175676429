import { useEffect, useState } from 'react'
import ConvertQuotesInObject from '../utils/jsonParser';
import axios from 'axios'

const useFetchData = (url, additionals, reFetch) => {
  const [FetchResult, setFetchResult] = useState()
  const [loading, setLoading] = useState(true)
  const [fetchError, setFetchError] = useState(null)

  useEffect(() => {
    const additionalData = additionals ? 
  
    {
      /*headers: {
      'Content-Type': 'application/json'
  },*/ 
  params: { token: localStorage.getItem('token'), [additionals.keyname]: additionals.value, [additionals.language]: additionals.languageValue } }
  
     : {params: { token: localStorage.getItem('token') }}
    
    const fetchData = async () => {
      setLoading(true);
      try {
        //console.log("tällä yrittää hakea: ", url, additionalData)
        const { data: response } = await axios.get(url, additionalData)
        const convertedResponse = ConvertQuotesInObject(response);
        //console.log("typeof raakadata servulta: ", typeof(response))
        //response.data ? setFetchResult(response.data) : setFetchResult(response)
        //console.log("convertattu response: ", convertedResponse)
        convertedResponse.data ? setFetchResult(convertedResponse.data) : setFetchResult(convertedResponse)
        if(response === undefined || response.length === 0 || null){
          setFetchError("returned undefined/empty/null")
        }
        if(response.data && response.data.result === "FAIL"){
          
        }
        //console.log("fetch componentista response on: ", response.data[0].form_title)
        //response.data[0].form_title.replace("\\n", "\n")
        //console.log("käännetty: ", response)
      } catch (error) {
        console.error('fetch error: ', error)
        //localStorage.clear()
        setFetchError(error)
        
      }
      setLoading(false)
    }
    if(url !== ""){
      fetchData()
    }
  }, [url, additionals.value, reFetch])

  return {
    FetchResult,
    loading,
    fetchError
  }
}

export default useFetchData
