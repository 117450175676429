import React, { useState } from "react";
import ButtonAppBar from "../common/components/MaterialUI/components/AppBar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useFetchData from "../api/FetchApi";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import MuiGrid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import configData from "../config.json";
import axios from "axios";
import { useSelector } from "react-redux";
import english from "../languages/english.json";
import finnish from "../languages/finnish.json";

const UserManagement = (props) => {
  const [open, setOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState({});
  const [value, setValue] = useState("");
  const [reFetch, setRefetch] = useState(false);
  const reduxLanguage = useSelector((state) => state.language.value.language);
  let useLanguage = reduxLanguage === "finnish" ? finnish : english;

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClickOpen = (id, name) => {
    setCurrentValue(id, name);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { FetchResult, loading, fetchError } = useFetchData(
    `${configData.SERVER_URL}/get/user/list/`,
    "",
    reFetch
  );

  const Grid = styled(MuiGrid)(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    '& [role="separator"]': {
      margin: theme.spacing(0, 2),
    },
  }));

  const dataSend = async () => {
    try {
      await axios.post(
        `${configData.SERVER_URL}/post/user/upgrade/`,
        null,
        {
          params: {
            token: localStorage.getItem("token"),
            uid: currentValue,
            role: value,
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
    handleClose();
    setRefetch(!reFetch);
  };

  return (
    <div>
      <ButtonAppBar />
      <div className="addButton">
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{useLanguage.UserManagement.dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {useLanguage.UserManagement.roleLabel}
            </DialogContentText>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {useLanguage.UserManagement.selectTitle}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={value}
                  label="Role"
                  onChange={handleChange}
                >
                  <MenuItem value="USER">USER</MenuItem>
                  <MenuItem value="ADMIN">ADMIN</MenuItem>
                  <MenuItem value="SUPERADMIN">SUPERADMIN</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              {useLanguage.UserManagement.cancel}
            </Button>
            <Button onClick={dataSend}>
              {useLanguage.UserManagement.accept}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div className="flexContainer">
        <div className="flexCenter">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading && <div>Loading</div>}
            {fetchError && (
              <h2 style={{ marginTop: "10%" }}>Oops, something went wrong!</h2>
            )}
            {!loading && fetchError === null && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "80vw" }}>
                  <div
                    style={{
                      // display: "flex",
                      justifyContent: "center",
                      marginTop: "55px",
                    }}
                  >
                    {FetchResult.hasOwnProperty("data") ? (
                      <Paper>
                        <List>
                          {Object.values(FetchResult.data).map(
                            (item, index) => (
                              <Paper style={{ margin: "2%" }}>
                                <ListItem
                                  button
                                  onClick={() =>
                                    handleClickOpen(item.id, item.name)
                                  }
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Grid
                                    container
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sm={4}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography component="legend">
                                        <h3>{item.name}</h3>
                                      </Typography>
                                      <Typography component="legend">
                                        <h2>{item.role}</h2>
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        onClick={() => handleClickOpen()}
                                      >
                                        {useLanguage.UserManagement.changeRole}
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </ListItem>
                              </Paper>
                            )
                          )}
                        </List>
                      </Paper>
                    ) : (
                      <h1>
                        Seems like you don't have permission to check users...
                      </h1>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserManagement;
