import React, { useState } from "react";
import ButtonAppBar from "../common/components/MaterialUI/components/AppBar";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import "../App.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { fi } from "date-fns/locale";
import DateTimePicker from "@mui/lab/DateTimePicker";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { Paper } from "@mui/material/";
import configData from "../config.json";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import MobileStepper from "@mui/material/MobileStepper";
import { useTheme } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import useFetchData from "../api/FetchApi";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SurveyPreview from "../common/components/MaterialUI/components/SurveyCreation/SurveyPreview";
import SurveySneakPeek from "../common/components/MaterialUI/components/SurveyCreation/SurveySneakPeek";
import Switch from "@mui/material/Switch";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import english from "../languages/english.json";
import finnish from "../languages/finnish.json";
import QuoteReplacer from "../utils/quoteReplacer";

const Survey = () => {
  const [open, setOpen] = useState(false);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [title, setTitle] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [questionAmount, setQuestionAmount] = useState([1]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [sneakPeekOpen, setSneakPeekOpen] = useState(false);
  const [previewData, setPreviewData] = useState({ id: null, url: "" });
  const [reFetch, setRefetch] = useState(false);
  const [switchState, setSwitchState] = useState({ 0: true });
  const [templateData, setTemplateData] = useState([]);
  const reduxLanguage = useSelector((state) => state.language.value.language);
  let useLanguage = reduxLanguage === "finnish" ? finnish : english;
  const [state, setState] = useState({
    title: "",
    //code: "",
    start: new Date(),
    end: new Date(),
    sciforms: [
      {
        title: "",
        sciquestionGroups: [
          {
            page: 1,
            title: "",
            description: "",
            questions: [
              {
                title: "",
                resultType: "FRACTIONAL",
                optionSetting: {
                  scaleMin: "1",
                  scaleMax: "7",
                  scaleMinLabel: "",
                  scaleMaxLabel: "",
                  optionType: "SELECT",
                },
                questionOptions: [{}],
              },
            ],
          },
        ],
      },
    ],
  });
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = useState(1);
  const maxSteps = steps;

  let validationSchema = Yup.object().shape({
    surveyTitle: Yup.string().required("Survey title is required"),
    formTitle: Yup.string().required("Form title is required"),
    questionGroupDescription: Yup.string().required(
      "Form description is required"
    ),
    questionGroup: Yup.string().required("Question group is required"),
    question1: Yup.string().required("Question title is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  //Fetchin data for listing survey templates that have been created before
  const { FetchResult, loading, fetchError } = useFetchData(
    `${configData.SERVER_URL}/get/surveys/`,
    "",
    reFetch
  );

  //console.log("fetch result: ", FetchResult);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleNext = () => {
    if (activeStep + 1 >= steps) {
      let newArr = [...state.sciforms];
      newArr[0].sciquestionGroups.push({
        page: activeStep + 2,
        //title: newArr[0].sciquestionGroups[activeStep].title,
        showinference: true,
        questions: [
          {
            title: "",
            resultType: "FRACTIONAL",
            optionSetting: {
              scaleMin: "1",
              scaleMax: "7",
              scaleMinLabel: "",
              scaleMaxLabel: "",
              optionType: "SELECT",
            },
            questionOptions: [{}],
          },
        ],
      });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setState({ ...state, sciforms: newArr });
      setSteps(steps + 1);
      let addQuestions = questionAmount;
      addQuestions.push(1);
      setQuestionAmount(addQuestions);
      setSwitchState({ ...switchState, [activeStep + 1]: true });
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const ariaLabel = { "aria-label": "description" };

  //Send data to server after making a new survey
  const sendData = async () => {
    const starttimeNewIso = startTime.toLocaleString("sv");
    const endtimeNewIso = endTime.toLocaleString("sv");
    const data5 = {
      survey: {
        title,
        //code: "",
        starts: starttimeNewIso,
        ends: endtimeNewIso,
        sciforms: state.sciforms,
      },
    };

    const formData = new FormData();

    try {
      //formData.set("data", JSON.stringify(data5));
      formData.set("data", JSON.stringify(data5, QuoteReplacer));
      //const jsonString = JSON.stringify(data5, customReplacer);
      //console.log("data lähtee näin: ", jsonString)

      fetch(
        `${
          configData.SERVER_URL
        }/post/surveys/add/?token=${localStorage.getItem("token")}`,
        {
          method: "POST",
          body: formData,
        }
      ).then(
        (res) => {
          if (res.status >= 400) {
            setOpenAlert(true);
            throw new Error("Server responds with error!");
          }
          setOpen(false);

          setRefetch(!reFetch);
          return res.json();
        },
        (err) => {
          console.log("err", err);
          setOpenAlert(true);
        }
      );
    } catch (error) {
      console.error("error while sending data... ", error);
      setOpenAlert(true);
    }
  };

  const handleFormTitleChange = (event) => {
    const {
      target: { value },
    } = event;
    let newArr = [...state.sciforms];
    newArr[0].title = value;
    setState({ ...state, sciforms: newArr });
  };

  const handleQuestionGroupDescriptionChange = (event) => {
    const {
      target: { value },
    } = event;
    let newArr = [...state.sciforms];
    newArr[0].sciquestionGroups[activeStep].description = value;
    setState({ ...state, sciforms: newArr });
  };

  const handleGroupChange = (event) => {
    const {
      target: { value },
    } = event;
    let newArr = [...state.sciforms];
    newArr[0].sciquestionGroups[activeStep].title = value;

    setState({ ...state, sciforms: newArr });
  };

  const handleQuestionChange = (event, index) => {
    const {
      target: { value },
    } = event;
    let newArr = [...state.sciforms];

    if (
      index > 0 &&
      newArr[0].sciquestionGroups[activeStep].questions[index] === undefined
    ) {
      newArr[0].sciquestionGroups[activeStep].questions.push({
        title: "",
        resultType: "FRACTIONAL",
        optionSetting: {
          scaleMin: "1",
          scaleMax: "7",
          scaleMinLabel: "",
          scaleMaxLabel: "",
          optionType: "SELECT",
        },
        questionOptions: [{}],
      });
    } else {
    }

    newArr[0].sciquestionGroups[activeStep].questions[index].title = value;
    setState({ ...state, sciforms: newArr });
  };

  const handleScaleTitleChange = (event, index) => {
    const {
      target: { name, value },
    } = event;
    let newArr = [...state.sciforms];

    if (
      index > 0 &&
      newArr[0].sciquestionGroups[activeStep].questions[index] === undefined
    ) {
      newArr[0].sciquestionGroups[activeStep].questions.push({
        title: "",
        resultType: "FRACTIONAL",
        optionSetting: {
          scaleMin: "1",
          scaleMax: "7",
          scaleMinLabel: "",
          scaleMaxLabel: "",
          optionType: "SELECT",
        },
        questionOptions: [{}],
      });
    } else {
    }

    if (name === "scaleMinTitle") {
      newArr[0].sciquestionGroups[activeStep].questions[
        index
      ].optionSetting.scaleMinLabel = value;
    } else {
      newArr[0].sciquestionGroups[activeStep].questions[
        index
      ].optionSetting.scaleMaxLabel = value;
    }
    setState({ ...state, sciforms: newArr });
  };

  const handleScaleChange = (event, index) => {
    const {
      target: { name, value },
    } = event;
    let newArr = [...state.sciforms];

    if (
      index > 0 &&
      newArr[0].sciquestionGroups[activeStep].questions[index] === undefined
    ) {
      newArr[0].sciquestionGroups[activeStep].questions.push({
        title: "",
        resultType: "FRACTIONAL",
        optionSetting: {
          scaleMin: "1",
          scaleMax: "7",
          scaleMinLabel: "",
          scaleMaxLabel: "",
          optionType: "SELECT",
        },
        questionOptions: [{}],
      });
    } else {
    }

    if (name === "minValue") {
      newArr[0].sciquestionGroups[activeStep].questions[
        index
      ].optionSetting.scaleMin = value;
    } else {
      newArr[0].sciquestionGroups[activeStep].questions[
        index
      ].optionSetting.scaleMax = value;
    }
    setState({ ...state, sciforms: newArr });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRadioChange = (event, questionIndex, radioIndex) => {
    let newArr = [...state.sciforms];
    newArr[0].sciquestionGroups[activeStep].questions[
      questionIndex
    ].questionOptions[radioIndex].title = event.target.value;
    setState({ ...state, sciforms: newArr });
  };
  const handleRadioOptionChange = (event, questionIndex, radioIndex) => {
    let newArr = [...state.sciforms];
    newArr[0].sciquestionGroups[activeStep].questions[
      questionIndex
    ].questionOptions[radioIndex].optionType = event.target.value;
    setState({ ...state, sciforms: newArr });
  };

  const handleTypeChange = (event, index) => {
    let newArr = [...state.sciforms];
    newArr[0].sciquestionGroups[activeStep].questions[
      index
    ].optionSetting.optionType = event.target.value;

    //Checking if the selectable is radio-type and creating a new item for it
    //in state or if type is something else, deletes the radio type from the state

    if (event.target.value === "FIELD") {
      newArr[0].sciquestionGroups[activeStep].questions[index].resultType =
        "TEXT";
    } else {
      newArr[0].sciquestionGroups[activeStep].questions[index].resultType =
        "FRACTIONAL";
    }

    if (event.target.value === "RADIO") {
      newArr[0].sciquestionGroups[activeStep].questions[index].questionOptions =
        [
          {
            title: "",
            value: "1",
            optionType: "OPTION",
          },
        ];
    } else if (
      newArr[0].sciquestionGroups[activeStep].questions[index].hasOwnProperty(
        "questionOptions"
      ) &&
      newArr[0].sciquestionGroups[activeStep].questions[index].questionOptions
        .length === 1
    ) {
      newArr[0].sciquestionGroups[activeStep].questions[index].questionOptions =
        [{}];
    }
    setState({ ...state, sciforms: newArr });
  };

  const removeQuestion = (event, index) => {
    let newArr = [...state.sciforms];
    newArr[0].sciquestionGroups[activeStep].questions.splice(index, 1);
    setState({ ...state, sciforms: newArr });
  };

  const removeRadio = (questionIndex, radioIndex) => {
    let newArr = [...state.sciforms];
    newArr[0].sciquestionGroups[activeStep].questions[
      questionIndex
    ].questionOptions.splice(radioIndex, 1);
    setState({ ...state, sciforms: newArr });
  };

  //function for generating new questions when clickin add icon

  const questionGenerator = () => {
    let newArr = [...state.sciforms];
    const returnable = [];
    for (
      let i = 0;
      i < newArr[0].sciquestionGroups[activeStep].questions.length;
      i++
    ) {
      if (
        i > 0 &&
        typeof newArr[0].sciquestionGroups[activeStep].questions[i] ===
          undefined
      ) {
        newArr[0].sciquestionGroups[activeStep].questions.push({
          title: "",
          resultType: "FRACTIONAL",
          optionSetting: {
            scaleMin: "1",
            scaleMax: "7",
            scaleMinLabel: "",
            scaleMaxLabel: "",
            optionType: "SELECT",
          },
          questionOptions: [{}],
        });
      } else {
      }

      let qq = 0;

      questionAmount.forEach((element, index) => {
        if (index < activeStep) {
          qq += element;
        }
      });

      returnable.push(
        <div key={`questiondiv${i}`}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              label={`${i + 1 + qq}. ${useLanguage.Surveys.questionTitle}`}
              inputProps={ariaLabel}
              variant="standard"
              multiline
              {...register(`question${i + 1}`)}
              error={errors[`question${i + 1}`] ? true : false}
              value={
                state.sciforms[0].sciquestionGroups[activeStep].questions[i]
                  .title
              }
              onChange={(e) => handleQuestionChange(e, i)}
            />
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              style={{ maxHeight: "50px" }}
              value={
                state.sciforms[0].sciquestionGroups[activeStep].questions[i]
                  .optionSetting.optionType
              }
              label="Type"
              onChange={(e) => handleTypeChange(e, i)}
            >
              <MenuItem value="SELECT">{useLanguage.Surveys.rating}</MenuItem>
              <MenuItem value="FIELD">{useLanguage.Surveys.field}</MenuItem>
              <MenuItem value="RADIO">{useLanguage.Surveys.option}</MenuItem>
            </Select>
            <Button onClick={(e) => removeQuestion(e, i)}>
              <RemoveCircleOutlineIcon color="error" />
            </Button>
          </div>
          {state.sciforms[0].sciquestionGroups[activeStep].questions[i]
            .optionSetting.optionType === "SELECT" && (
            <div>
              <TextField
                style={{ marginTop: "2%" }}
                name="scaleMinTitle"
                multiline
                label={useLanguage.Surveys.disagree}
                inputProps={ariaLabel}
                variant="standard"
                value={
                  state.sciforms[0].sciquestionGroups[activeStep].questions[i]
                    .optionSetting.scaleMinLabel
                }
                onChange={(e) => handleScaleTitleChange(e, i)}
              />
              <TextField
                multiline
                style={{ marginTop: "2%", marginLeft: "2%" }}
                name="scaleMaxTitle"
                label={useLanguage.Surveys.agree}
                inputProps={ariaLabel}
                variant="standard"
                value={
                  state.sciforms[0].sciquestionGroups[activeStep].questions[i]
                    .optionSetting.scaleMaxLabel
                }
                onChange={(e) => handleScaleTitleChange(e, i)}
              />
              <br />
              <br />
              <TextField
                id="outlined-number"
                label={useLanguage.Surveys.ratingScale}
                type="number"
                name="maxValue"
                onChange={(e) =>
                  e.target.value >
                    state.sciforms[0].sciquestionGroups[activeStep].questions[i]
                      .optionSetting.scaleMin && handleScaleChange(e, i)
                }
                value={
                  state.sciforms[0].sciquestionGroups[activeStep].questions[i]
                    .optionSetting.scaleMax
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          )}
          {state.sciforms[0].sciquestionGroups[activeStep].questions[i]
            .optionSetting.optionType === "RADIO" && (
            <div>
              <List>
                {newArr[0].sciquestionGroups[activeStep].questions[
                  i
                ].questionOptions.map((item, radioIndex) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <ListItem
                      key={`radioList${radioIndex}`}
                      style={{ maxWidth: "50%" }}
                    >
                      <TextField
                        multiline
                        value={
                          newArr[0].sciquestionGroups[activeStep].questions[i]
                            .questionOptions[radioIndex].title
                        }
                        onChange={(e) => handleRadioChange(e, i, radioIndex)}
                      ></TextField>
                    </ListItem>
                    <Select
                      key={`radioSelect${radioIndex}`}
                      style={{ margin: "2%" }}
                      value={
                        state.sciforms[0].sciquestionGroups[activeStep]
                          .questions[i].questionOptions[radioIndex].optionType
                      }
                      onChange={(e) =>
                        handleRadioOptionChange(e, i, radioIndex)
                      }
                    >
                      <MenuItem value="OPTION">
                        {useLanguage.Surveys.optionOption}
                      </MenuItem>
                      <MenuItem value="FIELD">
                        {useLanguage.Surveys.optionField}
                      </MenuItem>
                    </Select>
                    <Button onClick={(e) => removeRadio(i, radioIndex)}>
                      <RemoveCircleOutlineIcon color="error" />
                    </Button>
                  </div>
                ))}
                <Button
                  onClick={() => {
                    newArr[0].sciquestionGroups[activeStep].questions[
                      i
                    ].questionOptions.push({
                      title: "",
                      value: (
                        newArr[0].sciquestionGroups[activeStep].questions[i]
                          .questionOptions.length + 1
                      ).toString(),
                      optionType: "OPTION",
                    });
                    setState({ ...state, sciforms: newArr });
                  }}
                >
                  <AddIcon />{" "}
                </Button>
              </List>
            </div>
          )}
        </div>
      );
    }
    return returnable;
  };

  const addQuestion = () => {
    const prevState = [...state.sciforms];
    prevState[0].sciquestionGroups[activeStep].questions.push({
      title: "",
      resultType: "FRACTIONAL",
      optionSetting: {
        scaleMin: "1",
        scaleMax: "7",
        scaleMinLabel: "",
        scaleMaxLabel: "",
        optionType: "SELECT",
      },
      questionOptions: [{}],
    });
    validationSchema.concat(
      Yup.object().shape({
        [`question${prevState[0].sciquestionGroups[activeStep].questions.length}`]:
          Yup.string().required("Question title is required"),
      })
    );
    setState({ ...state, sciforms: prevState });
    let addQuestions = questionAmount;
    addQuestions[activeStep] += 1;
    setQuestionAmount(addQuestions);
  };

  const closePreview = () => {
    setPreviewOpen(false);
  };

  const closeSneakPeek = () => {
    setSneakPeekOpen(false);
  };

  const switchHandler = (e) => {
    let newArr = [...state.sciforms];
    newArr[0].sciquestionGroups[activeStep].showinference = e.target.checked;

    setState({ ...state, sciforms: newArr });
    setSwitchState({
      ...switchState,
      [activeStep]: e.target.checked,
    });
  };

  const openAsTemplate = () => {
    //setTemplateData(fetchData);
    //console.log("templateData: ", templateData);
    setOpen(true);
  }

  const handleSurveyTitleChange = (e) => {
    //setState
  }

  //console.log("sciforms: ", state.sciforms[0].sciquestionGroups)

  return (
    <div>
      <ButtonAppBar />
      <div className="addButton">
        <Fab color="primary" aria-label="add" onClick={handleClickOpen}>
          <AddIcon />
        </Fab>
        <SurveyPreview
          fetchData={FetchResult}
          id={previewData.id}
          url={previewData.url}
          open={previewOpen}
          setMaxSteps={setSteps}
          setTemplateData={setState}
          openAsTemplate={() => openAsTemplate()}
          onClose={() => closePreview()}
        />
        <SurveySneakPeek
          id={previewData.id}
          url={previewData.url}
          open={sneakPeekOpen}
          title={title}
          form={state.sciforms}
          onClose={() => closeSneakPeek()}
        />
        <Dialog open={open} onClose={handleClose}>
          <Dialog
            open={openAlert}
            onClose={handleCloseAlert}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Error while sending data to server...
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAlert}>Close</Button>
            </DialogActions>
          </Dialog>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <DialogTitle>{useLanguage.Surveys.addSurvey}</DialogTitle>
           
          </div>
          <Box>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                multiline
                name="title"
                required
                value={title !== null ? title : state.title}
                //label={useLanguage.Surveys.surveyTitle}
                label={useLanguage.Surveys.surveyTitle}
                variant="standard"
                disabled={activeStep !== 0}
                inputProps={ariaLabel}
                {...register("surveyTitle")}
                error={errors.surveyTitle ? true : false}
                onChange={(e) => setTitle(e.target.value)}
              />
              <br />

              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={fi}
                mask="____ __:__"
                style={{ marginTop: "2%", marginBottom: "2%" }}
              >
                <DateTimePicker
                  style={{ marginTop: "2%", marginBottom: "2%" }}
                  renderInput={(props) => <TextField {...props} />}
                  label={useLanguage.Surveys.startTime}
                  name="start"
                  value={startTime}
                  onChange={(newValue) => {
                    setStartTime(newValue);
                  }}
                />
              </LocalizationProvider>

              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={fi}
                style={{ margin: "2%", marginBottom: "2%" }}
              >
                <DateTimePicker
                  style={{ marginTop: "2%", marginBottom: "2%" }}
                  renderInput={(props) => <TextField {...props} />}
                  name="end"
                  label={useLanguage.Surveys.endTime}
                  value={endTime}
                  onChange={(newValue) => {
                    setEndTime(newValue);
                  }}
                />
              </LocalizationProvider>

              <TextField
                name="title"
                multiline
                value={state.sciforms[0].title}
                variant="standard"
                disabled={activeStep !== 0}
                label={useLanguage.Surveys.formTitle}
                inputProps={ariaLabel}
                {...register("formTitle")}
                error={errors.formTitle ? true : false}
                onChange={handleFormTitleChange}
              />
              <br />
              <Divider variant="middle" />
              <br />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <TextField
                    name="title"
                    label={useLanguage.Surveys.questionGroup}
                    multiline
                    inputProps={ariaLabel}
                    value={
                      state.sciforms[0].sciquestionGroups.length > 0 && 
                      state.sciforms[0].sciquestionGroups[activeStep].title
                        ? state.sciforms[0].sciquestionGroups[activeStep].title
                        : ""
                    }
                    variant="standard"
                    {...register("questionGroup")}
                    error={errors.questionGroup ? true : false}
                    onChange={handleGroupChange}
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          switchState.hasOwnProperty([activeStep])
                            ? switchState[activeStep]
                            : true
                        }
                        onChange={switchHandler}
                      />
                    }
                    label="Sisällytä analyyseihin"
                  />
                </div>

                <TextField
                  name="title"
                  multiline
                  value={
                    state.sciforms[0].sciquestionGroups.length > 0 && 
                    state.sciforms[0].sciquestionGroups[activeStep].description
                      ? state.sciforms[0].sciquestionGroups[activeStep]
                          .description
                      : ""
                  }
                  variant="standard"
                  label={useLanguage.Surveys.questionGroupDescription}
                  inputProps={ariaLabel}
                  {...register("questionGroupDescription")}
                  error={errors.questionGroupDescription ? true : false}
                  onChange={handleQuestionGroupDescriptionChange}
                />
              </div>
              <br />

              {questionGenerator()}

              <Fab
                color="primary"
                aria-label="add"
                onClick={() => addQuestion()}
              >
                <AddIcon />
              </Fab>
            </Box>
          </Box>
          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button size="small" onClick={handleSubmit(handleNext)}>
                {activeStep + 1 === steps
                  ? useLanguage.Surveys.newPage
                  : useLanguage.Surveys.next}
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                {useLanguage.Surveys.back}
              </Button>
            }
          />
          <DialogActions>
            <Button onClick={handleClose}>{useLanguage.Surveys.cancel}</Button>
            <Button onClick={() => sendData()}>
              {useLanguage.Surveys.accept}
            </Button>
          </DialogActions>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "2%",
            }}
          >
            <Button onClick={() => setSneakPeekOpen(true)} variant="contained">
              Esikatselu
            </Button>
          </div>
        </Dialog>
      </div>

      <div className="flexContainer">
        <div className="flexCenter">
          <h1>{useLanguage.Surveys.surveyTemplates}</h1>

          {loading && <div>Loading</div>}
          {fetchError && (
            <h2 style={{ marginTop: "10%" }}>Oops, something went wrong!</h2>
          )}
          {!loading && fetchError === null && (
            <Paper
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              <nav aria-label="main mailbox folders">
                <List>
                  {FetchResult.map((item) => (
                    <ListItem disablePadding key={item.survey}>
                      <ListItemButton
                        onClick={() => {
                          setPreviewOpen(true);
                          setPreviewData({
                            url: `${configData.SERVER_URL}/get/surveys/`,
                            id: item.survey,
                          });
                        }}
                      >
                        <ListItemText>{item.survey_title}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </nav>
            </Paper>
          )}
        </div>
      </div>
    </div>
  );
};
export default Survey;
