import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { Button } from "@mui/material";
import useFetchData from "../../../../../api/FetchApi";
import configData from "../../../../../config.json";
import { Add } from "@mui/icons-material";
import { current } from "@reduxjs/toolkit";
import QuoteReplacer from "../../../../../utils/quoteReplacer";

const CreateAnalytics = (props) => {
  const { onClose, data, open, previousResponseData, fetchData, prevPropsUrl } =
    props;
  const [translationOpen, setTranslationOpen] = useState(true);
  const [translationState, setTranslationState] = useState("");
  const [translationLanguage, setTranslationLanguage] = useState("");
  const [responses, setResponse] = useState({});
  const [fieldAmount, setFieldAmount] = useState({});
  const [groupData, setGroupData] = useState({});
  const [groupFetchSwitch, setGroupFetchSwitch] = useState(false);
  const [template, setTemplate] = useState("");
  const [templateData, setTemplateData] = useState({});
  const [templateUse, setTemplateUse] = useState(false);
  const [firstFromTemplate, setFirstFromTemplate] = useState({});
  const [formId, setFormId] = useState();

  // const data = PreviewData;

  //const [formId, setFormId] = useState();

  const { data2 } = useFetchData(prevPropsUrl, {
    keyname: "id",
    value: formId,
  });

  //console.log("data: ", data);

  const setGroupNames = async () => {
    const groupArray = await Object.values(data[0].data.pages).map((item) => {
      const returnable = Object.keys(item.groups).map((groupItem) => {
        return groupItem;
      });
      return returnable;
    });
    const parsedArray = [];
    if (groupArray.length > 0) {
      await groupArray.forEach((item) => {
        item.forEach((subItem) => {
          parsedArray.push(subItem);
        });
      });
    }
    const respQgroups = await axios(
      `${
        configData.SERVER_URL
      }/get/qgroupinfo/?gid=${parsedArray.toString()}&lang=fi_FIN&token=${localStorage.getItem(
        "token"
      )}`
    );
    //console.log("groupdata: ", respQgroups.data.data);
    setGroupData({ qGroups: respQgroups.data.data });
  };

  if (
    Object.keys(groupData).length === 0 &&
    open &&
    groupFetchSwitch === false
  ) {
    setGroupFetchSwitch(true);
    setGroupNames();
  }

  const sendData = async () => {
    try {
      const responseData =
        previousResponseData.length === 0
          ? Object.keys(responses).map((item) => {
              return {
                title: item,
                gid: item,
                answerCount: 0,
                fixedLimit: 0,
                autoMode: false,
                resSubConfigs: responses[item].resSubConfigs,
              };
            })
          : Object.keys(responses).map((item) => {
              const subData = Object.keys(responses[item]).map((subItem) => {
                return { id: subItem, response: responses[item][subItem] };
              });
              return {
                id: item,
                lang: translationLanguage,
                resSubConfigs: subData,
              };
            });

      const responseDataFixed =
        previousResponseData.length === 0
          ? {
              resMainConfigs: responseData,
            }
          : {
              resMainConfig: responseData,
            };


      const url =
        previousResponseData.length === 0
          ? `${
              configData.SERVER_URL
            }/post/response/add/?token=${localStorage.getItem("token")}`
          : `${
              configData.SERVER_URL
            }/post/translation/response/?token=${localStorage.getItem(
              "token"
            )}`;

      const formData = new FormData();
      /*formData.set("data", JSON.stringify(responseDataFixed));

      if (Object.keys(firstFromTemplate).length > 0) {
        console.log("yrittää lähettää näin: ", firstFromTemplate);
        formData.set("data", JSON.stringify(firstFromTemplate));
      } else {
        console.log("yrittää lähettää näin: ", responseDataFixed);
        formData.set("data", JSON.stringify(responseDataFixed));
      }
*/
      //console.log("translation: ", translationLanguage)
      translationLanguage === "fi_FIN" ?
      formData.set("data", JSON.stringify(firstFromTemplate, QuoteReplacer))
      :
      formData.set("data", JSON.stringify(responseDataFixed, QuoteReplacer))

      fetch(url, {
        method: "POST",
        body: formData,
      }).then(
        (res) => {
          //console.log("formData lähtee: ", formData)

          if (res.status >= 400) {
            throw new Error("Server responds with error!");
          }
          window.location.reload(false);
          return res.json();
        },
        (err) => {
          console.log("err", err);
          console.error("error while sending data... ", err);
        }
      );

      

    } catch (error) {
      console.error("error while sending data... ", error);
    }
  };

  const handleClose = () => {
    onClose();
    setTranslationOpen(true);
  };

  const handleTranslationClick = (lang) => {
    setTranslationLanguage(lang);
    setTranslationOpen(false);
  };
  const handleTranslationClose = () => {
    setTranslationOpen(false);
  };

 /* const handleResponseChange = (e, groupID, index, name, i) => {
    //console.log("responseChangesta e: ", e, "groupID: ", groupID, "index: ", index, "name: ", name)
    let resSub = [];
    if (responses[groupID]) {
      resSub = responses[groupID].resSubConfigs;
    }
    resSub[i] = {
      ...resSub[i],
      [name]: e.target.value,
    };

    setResponse({
      ...responses,
      [groupID]: {
        ...responses[groupID],
        resSubConfigs: resSub,
        //title: e.target.value
      },
    });
  };
*/

 /* const generateFields = (groupItem, groupIndex, pageIndex) => {
    //console.log("generatefieldsistä: ", pageIndex)
    console.log("generatefieldsistä: ", groupItem);
    console.log("verrokki firstFromTemplate: ", firstFromTemplate);
    let fields = [];
    /*for (let i = 0; i < fieldAmount[groupItem[0].group]; i++) {
      fields.push(
        <div
          style={{
            marginTop: "2%",
            marginLeft: "2%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <TextField
              id="filled-number"
              multiline
              label="Under or equal value"
              type="number"
              style={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              value={
                responses[groupItem[0].group] && responses[groupItem[0].group].resSubConfigs[i] &&
                responses[groupItem[0].group].resSubConfigs[i].fixedValue ? responses[groupItem[0].group].resSubConfigs[i].fixedValue
                :
                Object.keys(firstFromTemplate).length > 0 ? firstFromTemplate.resMainConfigs[groupIndex].resSubConfigs[i].fixedValue
                : 0
              }
              onChange={(e) =>
                handleResponseChange(
                  e,
                  groupItem[0].group,
                  groupIndex,
                  "fixedValue",
                  i
                )
              }
              variant="filled"
            />
            {/*<TextField
            id="filled-number"
            label="percentage"
            type="number"
            style={{ width: "50%" }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) =>
              handleResponseChange(
                e,
                groupItem[0].group,
                groupIndex,
                "percentage",
                i
              )
            }
            variant="filled"
          />*/
    /*
          </div>
          <TextField
            label="Response text"
            style={{ width: "100%" }}
            multiline
            value={
              responses[groupItem[0].group] && responses[groupItem[0].group].resSubConfigs[i] &&
              responses[groupItem[0].group].resSubConfigs[i].response ? responses[groupItem[0].group].resSubConfigs[i].response
              :
              Object.keys(firstFromTemplate).length > 0 ? firstFromTemplate.resMainConfigs[groupIndex].resSubConfigs[i].response
              : ""
            }
            onChange={(e) =>
              handleResponseChange(
                e,
                groupItem[0].group,
                groupIndex,
                "response",
                i
              )
            }
          />
        </div>
          }
      );*/
/*
    return (
      <div>
        {firstFromTemplate.resMainConfigs[groupIndex]}
        {/*<div
          style={{
            marginLeft: "5%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          Group name:{" "}
          {groupData.qGroups ? groupData.qGroups[pageIndex].title : ""}
          {fields.map((item) => item)}
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            style={{ marginTop: "2%" }}
            onClick={() =>
              setFieldAmount({
                ...fieldAmount,
                [groupItem[0].group]: fieldAmount[groupItem[0].group]
                  ? fieldAmount[groupItem[0].group] + 1
                  : 1,
              })
            }
          >
            <Add />
          </Button>
          </div>}
      </div>
    );
  };
*/
  const handleResponseTranslation = (e, mainID, subID) => {
    console.log("responseTranslationista")
    setResponse({
      ...responses,
      [mainID]: {
        ...responses[mainID],
        [subID]: e.target.value,
        //title: e.target.value
      },
    });
    //console.log(e.target.value, " ", id)
  };

  const handleTemplateChange = async (e, id) => {
    //console.log("targett: ", e.target.value);
    setTemplateUse(true);
    setTemplate(e.target.value);
    const fetchData = async () => {
      //setLoading(true);
      try {
        /*const translationData = await axios(
          `${
            configData.SERVER_URL
          }/get/survey/?code=${e.target.value}&lang=${translationLanguage}&token=${localStorage.getItem(
            "token"
          )}`
        );

        if(translationData === undefined || translationData.length === 0 || null){
          //setFetchError("returned undefined/empty/null")
          console.log("returned undefined/empty/null")
        }
        if(translationData.data && translationData.data.result === "FAIL"){
          console.log("fail...")
        }
*/

        //console.log("translation template data: ", translationData.data.data[0])
        //setTemplateData(translationData.data.data[0])
        //setFormId(translationData.data.data[0].form)

        //tämä on sama fetch kuin previousResponseDatassa käytetään..
        //Eli mallaa tähän group namet avuksi mutta jätä xid:t sun muut koska niitä tarvii kantaan kirjoituksessa...
        //kun otetaan template pohjaksi, idt menee sekaisin handlechanges ja sen takia ei kirjaudu oikein ->
        //generoi state jossa käytetään nykyisesen surveyn tietoja, mutta vain nimet tuodaan siihen
        //templatesta :)

        //tähän myös pitää tehä funkkari et jos kielenä on suomi, niin datarakenne muuttuu, täl hetkel bugi,
        //jostain syystä kun uutta tekee niin previousResponseData on tyhjä?.. Tsekkaa console logil
        //kuinka survey generation toimii tyhjästä ja kato sendatas miltä se lähtevä data näyttää ->
        //esim. tuleeko sinne id:tä ollenkaan? ainakin ne valuet pitää kopioida tosta /response/for routesta!
        //Tässä voi myös katsoa senDatan response statea joka generoidaan...

        //tee uutta responsea, kato kuinka state muuttuu ja kopioi sen kaltainen uusi elementti näistä ja tee

        //testifunkkari on melkein sama kuin nyt mitä haetaan, muuta siihen vain oikeat id:t current surveystä,
        //myös generoi visuaalisesti ne fixedvalue elementit jotenkin siihen

        //eli datassa on nykyisen survey datat, kopsaa idt siitä ja syötä testifunkkarijuttuun
        //sitten tee uus state ja datan lähetyksessä käytä sitä uutta statea, jos previousResponse.data.length === 0

        //nyt selvitetään kuinka saadaa oikeat gidit routeihin
        //taitaa olla muute done, mutta visuaalisuudet kuntoon, eli kenttien (fixedvaluet yms) näkyviin
        //kun template haetaan

        const surveyFetchData = await axios(
          `${configData.SERVER_URL}/get/translation/surveys/?id=${
            e.target.value
          }&token=${localStorage.getItem("token")}`
        ).catch((err) => console.log("ERROR from fetchin surveyData: ", err));

        /*console.log(
          "SurveyFetchData on: ",
          surveyFetchData,
          "ja previousData on: ",
          previousResponseData
        );*/

        const temporaryData = previousResponseData;

        //Tähän lisächecki et onko prev.data.length === 0 (onko eka palaute)

        const newData = temporaryData.map((item, index) => ({
          id: item.id,
          key: item.key,
          languageCode: item.languageCode,
          specification: item.specification,
          translationType: item.translationType,
          value: surveyFetchData.data.data[translationLanguage].r[index].value,
        }));
        //setTemplateData(surveyFetchData.data.data[translationLanguage].r)
        //console.log("asetetaan tämmönen new dataksi: ", newData);
        setTemplateData(newData);
        if (previousResponseData.length === 0) {
          setResponse({
            ...responses,
            [0]: {
              ...responses[0],
              resSubConfigs: 0,
              //title: e.target.value
            },
          });
        }

        //temporaryData

        //tässä pitää katsoa onko kyseessä ryhmän selite vai nimi, muuten tulee kaks kertaa samat
        const testifunktio = async () => {
          const parsedGroup = await surveyFetchData.data.data[
            translationLanguage
          ].g.filter((item) => item.specification === "title");
          //console.log("parsedGroup: ", parsedGroup);
          const tes = await Promise.all(
            Object.values(parsedGroup).map(async (item, index) => {
              return fetch(
                `${configData.SERVER_URL}/get/response/for/?gid=${
                  previousResponseData.length !== 0 ? item.key : item.id
                }&token=${localStorage.getItem("token")}`
              ).then((response) => response.json());
            })
          );
          return tes;
        };

        //this will be done only if there are no previous responses made for this survey
        if (previousResponseData.length === 0) {
          testifunktio().then((response) => {
            const currentSurveysGids = Object.values(data[0].data.pages).map(
              (item) => {
                return Object.keys(item.groups)[0];
              }
            );
            //console.log("currentSurveyGids: ", currentSurveysGids);
            //console.log("testifunkkarista: ", response);
            const testireturn = {
              resMainConfigs: response.map((item, index) => {
                delete item.data.resMainConfigs[0].id;
                item.data.resMainConfigs[0].gid = currentSurveysGids[index];
                item.data.resMainConfigs[0].title = currentSurveysGids[index];
                return item.data.resMainConfigs[0];
              }),
            };
            /*console.log(
              "parsittu testireturn on: ",
              testireturn,
              "ja nykyinen data on: ",
              data
            );*/
            setFirstFromTemplate(testireturn);

            const templateFieldAmounts = {};
            testireturn.resMainConfigs.forEach((item) => {
              templateFieldAmounts[item.gid] = item.resSubConfigs.length;
            });
            //console.log("templateFieldAmounts: ", templateFieldAmounts);
            setFieldAmount(templateFieldAmounts);
          });
        }

        // console.log("surveyFetchData: ", surveyFetchData.data.data[translationLanguage].g)
        /*const groupArray = await Object.values(
            surveyFetchData.data.data[translationLanguage].g
          ).map(async (item) => {
          const fetched = await axios(
            `${
              configData.SERVER_URL
            }/get/response/for/?gid=${previousResponseData.length !== 0 ? item.key : item.id}&token=${localStorage.getItem(
              "token"
            )}`
          )
          .then(res => res)
          .catch((err) => console.log("ERROR from fetchin survey response Data: ", err));
            console.log("fetched on: ", fetched.data.data);
            const test = fetched;
           return test;
            });

          console.log("groupArray: ", groupArray)
              */
        /*const surveyResponseData = await axios(
              `${
                configData.SERVER_URL
              }/get/response/for/?gid=${groupArray.toString()}&token=${localStorage.getItem(
                "token"
              )}`
            ).catch((err) => console.log("ERROR from fetchin survey response Data: ", err));
            //setTemplateData(surveyFetchData.data);

          /*fetchSurveyData().then((res) => {
            fetchResponses().then((respRes) => {
              console.log("response data: ", respRes.data)
            })
            setTemplateData(res.data.data[translationLanguage].r);
          });*/

        //console.log("survey response data: ", surveyResponseData)

        //console.log("ja translationstate näyttää tältä: ", translationState)
      } catch (error) {
        console.error("fetch error: ", error);
      }
      //setLoading(false)
    };
    fetchData();
  };

  //console.log("templatedata: ", templateData);

  const handleResponseChange2 = (e, groupIndex, resSubConfigIndex, type) => {
    //console.log("handleresponse kakkosesta")
    let subArray = firstFromTemplate.resMainConfigs[groupIndex].resSubConfigs;
    type === "fixedValue"
      ? (subArray[resSubConfigIndex].fixedValue = e.target.value)
      : (subArray[resSubConfigIndex].response = e.target.value);

    let array1 = firstFromTemplate.resMainConfigs;
    array1[groupIndex] = {
      ...firstFromTemplate.resMainConfigs[groupIndex],
      resSubConfigs: subArray,
    };

    setFirstFromTemplate({
      ...firstFromTemplate,
      resMainConfigs: array1,
    });
    //console.log("tällä mennään: ", firstFromTemplate);
  };

  const addToResponses = (groupIndex) => {
    let subArray = firstFromTemplate.resMainConfigs[groupIndex].resSubConfigs;
    subArray.push({
      fixedValue: 0,
      response: ""
    })
    let array1 = firstFromTemplate.resMainConfigs;
    array1[groupIndex] = {
      ...firstFromTemplate.resMainConfigs[groupIndex],
      resSubConfigs: subArray,
    };

    setFirstFromTemplate({
      ...firstFromTemplate,
      resMainConfigs: array1,
    });
    //console.log("tällä mennään: ", firstFromTemplate);
  };

  const addToFirstResponses = (groupIndex) => {
    //console.log("first response data: ", data[0].data)
    const firstItem = Object.values(data[0].data.pages).map((item, index) => {
      return {
        answerCount: 0,
        autoMode: false,
        fixedLimit: 0,
        gid: Object.keys(item.groups)[0],
        title: Object.keys(item.groups)[0],
        resSubConfigs: [{
          fixedValue: 0,
          response: ""
        }]
      }
    })

    setFirstFromTemplate({
      resMainConfigs: firstItem
    });
    //console.log("tällä mennään: ", firstFromTemplate);
  };

  //console.log("ennen returnia state on: ", firstFromTemplate);
  //console.log("groupData: ", groupData)
  return (
    <div>
      <Dialog fullWidth open={open}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle>SurveyPreview</DialogTitle>
          <FormControl style={{ margin: "5%" }}>
            <InputLabel>Pohja</InputLabel>
            <Select
              style={{ width: "200px" }}
              value={template}
              onChange={handleTemplateChange}
            >
              {fetchData.map((item) => (
                <MenuItem value={item.survey}>{item.survey_title}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1>Analytics language: {translationLanguage}</h1>
              {data !== undefined && data.length > 0 && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h4>Survey title: {data[0].survey}</h4>

                  <h4>Form title: {data[0].form_title}</h4>

                  {Object.keys(templateData).length > 0 ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {templateData.map((item, index) => (
                        <div>
                          <p>{`ID: ${item.xid}, ${index}. response: `}</p>
                          <TextField
                            multiline
                            style={{ margin: "2%" }}
                            label={item.value}
                            onChange={(e) =>
                              handleResponseTranslation(e, item.xid, item.id)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  ) : previousResponseData.length > 0 ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {previousResponseData.map((item, index) => (
                        <div>
                          <p>{`ID: ${item.xid}, ${index}. response: `}</p>
                          <TextField
                            multiline
                            style={{ margin: "2%" }}
                            label={item.value}
                            onChange={(e) =>
                              handleResponseTranslation(e, item.xid, item.id)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>
                      {Object.keys(firstFromTemplate).length > 0 ? (
                        <div>
                          {firstFromTemplate.resMainConfigs.map(
                            (item, index) => (
                              <div>
                                <h2>{Object.keys(groupData).length > 0 ? groupData.qGroups[index].title
                                : item.title}
                                </h2>
                                {item.resSubConfigs.map(
                                  (subItem, subItemIndex) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <TextField
                                        id="filled-number"
                                        multiline
                                        label="Under or equal value"
                                        type="number"
                                        style={{ width: "100%" }}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        value={subItem.fixedValue}
                                        onChange={(e) =>
                                          handleResponseChange2(
                                            e,
                                            index,
                                            subItemIndex,
                                            "fixedValue"
                                          )
                                        }
                                        variant="filled"
                                      />
                                      :{" "}
                                      <TextField
                                        onChange={(e) =>
                                          handleResponseChange2(
                                            e,
                                            index,
                                            subItemIndex,
                                            "response"
                                          )
                                        }
                                        value={subItem.response}
                                      ></TextField>
                                    </div>
                                  )
                                )}
                                <Button
                                  variant="contained"
                                  style={{ marginTop: "2%" }}
                                  onClick={() => addToResponses(index)}
                                >
                                  <Add />
                                </Button>
                              </div>
                            )
                          )}
                        </div>
                      ) : 
                      
                      <div>
                        {Object.values(data[0].data.pages).map((item, index) => {
                          return <div>
                            <h2>
                              {/*Object.keys(item.groups)[0]*/}
                              {Object.keys(groupData).length > 0 ? groupData.qGroups[index].title
                              
                            : Object.keys(item.groups)[0]}:
                            </h2>
                                 <Button
                                  variant="contained"
                                  style={{ marginTop: "2%" }}
                                  onClick={() => addToFirstResponses(index)}
                                >
                                  <Add />
                                </Button>
                          </div>
                        })}
                       
                      </div>
                      }
                    </div>
                  )}
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: "2",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    alignSelf: "flex-end",
                    marginTop: "5%",
                    margin: "2%",
                  }}
                  color="error"
                  onClick={() => handleClose()}
                >
                  Peruuta
                </Button>
                <Button
                  variant="contained"
                  style={{
                    alignSelf: "flex-end",
                    marginTop: "5%",
                    margin: "2%",
                  }}
                  color="success"
                  onClick={() => sendData()}
                >
                  Lähetä
                </Button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        open={open && translationOpen}
        onClose={handleTranslationClose}
      >
        <DialogTitle>
          {previousResponseData.length > 0
            ? "Valitse käännöskieli"
            : "Tee ensimmäinen palaute"}
        </DialogTitle>

        {previousResponseData.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Button
              style={{ marginTop: "5%", marginLeft: "15%", marginRight: "15%" }}
              variant="contained"
              onClick={() => handleTranslationClick("en_ENG")}
            >
              Englanti
            </Button>
            <Button
              style={{ marginTop: "5%", marginLeft: "15%", marginRight: "15%" }}
              variant="contained"
              onClick={() => handleTranslationClick("hu_HUN")}
            >
              Unkari
            </Button>
            <Button
              style={{
                marginTop: "5%",
                marginLeft: "15%",
                marginRight: "15%",
                marginBottom: "5%",
              }}
              variant="contained"
              onClick={() => handleTranslationClick("de_DEU")}
            >
              Saksa
            </Button>
          </div>
        ) : (
          <Button
            style={{
              margin: "5%",
              marginLeft: "15%",
              marginRight: "15%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            variant="contained"
            onClick={() => handleTranslationClick("fi_FIN")}
          >
            Suomi
          </Button>
        )}
      </Dialog>
    </div>
  );
};

export default CreateAnalytics;
