import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import english from "../languages/english.json";
import finnish from "../languages/finnish.json";
import configData from "../config.json";

const ResetPassword = (props) => {
  const reduxLanguage = useSelector((state) => state.language.value.language);
  const useLanguage =
    reduxLanguage === "finnish"
      ? finnish
      : localStorage.getItem("language") === "english"
      ? english
      : english;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
    .required(useLanguage.Register.Errors.emailRequired)
    .email(useLanguage.Register.Errors.emailInvalid),
    resetCode: Yup.string()
      .required(useLanguage.ResetPassword.Errors.resetCodeRequired),
    password: Yup.string()
      .required(useLanguage.ResetPassword.Errors.passwordRequired)
      .min(6, useLanguage.ResetPassword.Errors.passwordMin)
      .max(40, useLanguage.ResetPassword.Errors.passwordMax),
    confirmPassword: Yup.string()
      .required(useLanguage.ResetPassword.Errors.confirmPassword)
      .oneOf(
        [Yup.ref("password"), null],
        useLanguage.ResetPassword.Errors.passwordNotMatch
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const renewalCode = params.get("renewalcode");

  const theme = createTheme();
  const [registerError, setRegisterError] = useState(false);
  const [newCode, setNewCode] = useState(null);
  //const [renewalCode, setRenewalCode] = useState()

  const onSubmit = (data) => {
    const registerdata = new FormData();
    for (let key in data) {
      registerdata.append(key, data[key]);
    }
    const email = registerdata.get("email");
    const newpassword = registerdata.get("password");
    const renewalcode = registerdata.get("resetCode");

    const sendRegisterData = async () => {
      try {
        const res = await axios.post(
          `${configData.SERVER_URL}/post/user/password/input/`,
          null,
          { params: { renewalcode, newpassword, email } }
        );
        if (res.data && res.data.result === "SUCCESS") {
          alert(useLanguage.ResetPassword.resetSuccessFull)
          navigate("/login");
        } else {
          setRegisterError(true);
        }
      } catch (error) {
        console.error("error, register error: ", error);
      }
    };
    sendRegisterData();
  };

  const handleCodeChange = (e) => {
    setNewCode(e.target.value)
  }

  const navigate = useNavigate();
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {useLanguage.ResetPassword.title}
          </Typography>
          <Box onSubmit={(e) => e.preventDefault()}>
           

            <TextField
              margin="normal"
              required
              fullWidth
              value={newCode !== null ? newCode : renewalCode ? renewalCode : ""}
              id="resetCode"
              name="resetCode"
              label={useLanguage.ResetPassword.resetCode}
              type="name"
              
              {...register("resetCode")}
              error={errors.resetCode ? true : false}
              onChange={handleCodeChange}
            />
            <Typography variant="inherit" color="textSecondary">
              {errors.resetCode?.message}
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={useLanguage.Register.email}
              name="email"
              autoComplete="email"
              autoFocus
              {...register("email")}
              error={errors.email ? true : false}
            />
            <Typography variant="inherit" color="textSecondary">
              {errors.email?.message}
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label={useLanguage.ResetPassword.newpassword}
              type="password"
              {...register("password")}
              error={errors.password ? true : false}
            />
            <Typography variant="inherit" color="textSecondary">
              {errors.password?.message}
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="confirmPassword"
              name="confirmPassword"
              label={useLanguage.ResetPassword.confirmnewpassword}
              type="password"
              {...register("confirmPassword")}
              error={errors.confirmPassword ? true : false}
            />
            <Typography variant="inherit" color="textSecondary">
              {errors.confirmPassword?.message}
            </Typography>
            {registerError && (
              <p style={{ color: "red" }}>Oops, something went wrong...</p>
            )}
             {/*<FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={props.useLanguage.remember}
            />*/}
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {useLanguage.ResetPassword.submit}
            </Button>
            <Grid container>
              <Grid item>
                <Link
                  component="button"
                  onClick={() => navigate("/login")}
                  variant="body2"
                >
                  {useLanguage.ResetPassword.login}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ResetPassword;
