import { createSlice } from '@reduxjs/toolkit'

export const languageSlice = createSlice({
  name: 'language',
  initialState: {
    value: {language: localStorage.getItem("language") ? localStorage.getItem("language") : "finnish"}
    
  },
  //default: return state,
  reducers: {
    setLanguageSlice: (state, action) => {
      state.value.language = action.payload;
    },
    
  }
})

// Action creators are generated for each case reducer function
export const { setLanguageSlice, setTranslationSlice } = languageSlice.actions

export default languageSlice.reducer