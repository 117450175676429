import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useNavigate } from 'react-router-dom'
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { setLanguageSlice } from '../../../../redux/languageSlice'
import { useDispatch, useSelector } from "react-redux";
import english from '../../../../languages/english.json';
import finnish from '../../../../languages/finnish.json';

export default function ButtonAppBar () {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [language, setLanguage] = React.useState("");
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const navigate = useNavigate()
  const handleChange = (event) => {
    setLanguage(event.target.value);
    dispatch(setLanguageSlice(event.target.value))
    localStorage.setItem('language', event.target.value)
    
  };
  const reduxLanguage = useSelector((state) => state.language.value.language);
  let useLanguage = reduxLanguage === "finnish" ? finnish : english;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {localStorage.getItem("authenticated") &&
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          }
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            {(localStorage.getItem("role") === "ADMIN" || localStorage.getItem("role") === "SUPER") && (
            <MenuItem
              onClick={() => {
                handleClose()
                navigate('/survey')
              }}
            >
             {useLanguage.AppBar.surveys}
            </MenuItem>
            )}
             {(localStorage.getItem("role") === "ADMIN" || localStorage.getItem("role") === "SUPER") && (
            <MenuItem
              onClick={() => {
                handleClose()
                navigate('/usermanagement')
              }}
            >
              {useLanguage.AppBar.userManagement}
            </MenuItem>
            )}
            <MenuItem onClick={()=>{
              localStorage.clear()
              handleClose()
              navigate('/login')
              }}>{useLanguage.AppBar.logout}</MenuItem>
          </Menu>
          {/*<Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            onClick={() => {
              navigate('/')
            }}
          >*/}
          <div style={{width: "100%", display: "flex", justifyContent: "flex-start"}}>
          <Button style={{color: "white"}} variant="text"  onClick={() => {
              navigate('/')
            }}>
            Diginet
          </Button>
          </div>
          <FormControl sx={{ m: 1, minWidth: 120}}>
          <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={language !== "" ? language : localStorage.getItem("language") ? localStorage.getItem("language") : "finnish"}
          //color="white"
          style={{color:"white"}}
          label="language"
          onChange={handleChange}
        >
          <MenuItem value={"finnish"}>Finnish</MenuItem>
          <MenuItem value={"english"}>English</MenuItem>
        </Select>
        </FormControl>
          {
          localStorage.getItem("username") ? null
          : <Button color="inherit" onClick={() => {
            navigate('/login')
          }}>{useLanguage.AppBar.login}</Button>}
          
        </Toolbar>
      </AppBar>
    </Box>
  )
}
