//Converting our own quotation escapes "&quote;" that are stored in database
//Back to the original quotation marks for showing data properly

const ConvertQuotesInObject = (obj) => {
    //console.log("starting converting this: ", obj)
    if (typeof obj === 'string') {
      return obj.replace(/&quote;/g, '"');
    } else if (Array.isArray(obj)) {
      return obj.map(item => ConvertQuotesInObject(item));
    } else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((newObj, key) => {
        newObj[key] = ConvertQuotesInObject(obj[key]);
        return newObj;
      }, {});
    }
    return obj;
  };

  export default ConvertQuotesInObject;