import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import { Button, Menu } from "@mui/material";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import axios from 'axios'
import useFetchData from "../../../../../api/FetchApi";
import configData from "../../../../../config.json";
import QuoteReplacer from "../../../../../utils/quoteReplacer";

const SurveyTranslation = (props) => {
  const { onClose, data, open, fetchData } = props;
  const [translationOpen, setTranslationOpen] = useState(true);
  const [translationState, setTranslationState] = useState("");
  const [translationLanguage, setTranslationLanguage] = useState("finnish");
  const [template, setTemplate] = useState("");
  const [templateData, setTemplateData] = useState({});
  const [templateUse, setTemplateUse] = useState(false);
  const [loading, setLoading] = useState(false);

  //console.log("fetchData to use: ", fetchData, "adn data: ", data)

  const sendData = async () => {
    try {
      const formData = new FormData();
      formData.set("data", JSON.stringify(translationState, QuoteReplacer));
      fetch(
        `${
          configData.SERVER_URL
        }/post/translation/survey/?token=${localStorage.getItem("token")}`,
        {
          method: "POST",
          body: formData,
        }
      ).then(
        (res) => {
          if (res.status >= 400) {
            throw new Error("Server responds with error!");
          }

          window.location.reload(false);
          return res.json();
        },
        (err) => {
          console.log("err", err);

          console.error("error while sending data... ", err);
        }
      );
    } catch (error) {
      console.error("error while sending data... ", error);
      onClose();
    }
  };

  //setting the fetched data to state for generating correct textfields to use for translates
  if (data !== undefined && translationState === "" && !translationOpen) {
    setTranslationState({
      survey: {
        id: data[0].survey,
        title: null,
        sciforms: [
          {
            [translationLanguage]: {
              id: data[0].survey,
              title: null,
              sciquestionGroups: [],
            },
          },
        ],
      },
    });
  }

  const handleClose = () => {
    onClose();
    setTranslationOpen(true);
    setTranslationState("");
  };

  const handleTranslationClick = (lang) => {
    setTranslationLanguage(lang);
    setTranslationOpen(false);
  };
  const handleTranslationClose = () => {
    setTranslationOpen(false);
  };

  const setSurveyTitle = (e, surveyID) => {
    setTemplateUse(false);
    setTranslationState({
      ...translationState,
      survey: {
        ...translationState.survey,
        title: e.target.value,
        id: surveyID,
      },
    });
  };

  const setFormTitle = (e, formID) => {
    const newArray = translationState.survey.sciforms;
    newArray[0] = {
      ...newArray[0],
      [translationLanguage]: {
        ...newArray[0][translationLanguage],
        title: e.target.value,
        id: formID,
      },
    };
    setTranslationState({
      ...translationState,
      survey: {
        ...translationState.survey,
        sciforms: newArray,
      },
    });
  };

  const setQuestionGroupTitle = (e, groupIndex, groupItem) => {
    const newArray = translationState.survey.sciforms;

    const newQuestionGroupArray =
      translationState.survey.sciforms[0][translationLanguage]
        .sciquestionGroups;

    if (
      !translationState.survey.sciforms[0][translationLanguage]
        .sciquestionGroups[groupIndex]
    ) {
      newQuestionGroupArray[groupIndex] = {
        title: e.target.value,
        id: Object.keys(groupItem.groups)[0],
        description: "",
        questions: [],
      };
    } else {
      newQuestionGroupArray[groupIndex] = {
        ...newQuestionGroupArray[groupIndex],
        title: e.target.value,
        id: Object.keys(groupItem.groups)[0],
      };
    }

    newArray[0] = {
      ...newArray[0],
      [translationLanguage]: {
        ...newArray[0][translationLanguage],
        sciquestionGroups: newQuestionGroupArray,
      },
    };
    setTranslationState({
      ...translationState,
      survey: {
        ...translationState.survey,
        sciforms: newArray,
      },
    });
  };

  const setQuestionGroupDescription = (e, groupIndex, groupItem) => {
    const newArray = translationState.survey.sciforms;

    const newQuestionGroupArray =
      translationState.survey.sciforms[0][translationLanguage]
        .sciquestionGroups;

    if (
      !translationState.survey.sciforms[0][translationLanguage]
        .sciquestionGroups[groupIndex]
    ) {
      newQuestionGroupArray[groupIndex] = {
        title: "",
        id: Object.keys(groupItem.groups)[0],
        description: e.target.value,
        questions: [],
      };
    } else {
      newQuestionGroupArray[groupIndex] = {
        ...newQuestionGroupArray[groupIndex],
        description: e.target.value,
        id: Object.keys(groupItem.groups)[0],
      };
    }

    newArray[0] = {
      ...newArray[0],
      [translationLanguage]: {
        ...newArray[0][translationLanguage],
        sciquestionGroups: newQuestionGroupArray,
      },
    };
    setTranslationState({
      ...translationState,
      survey: {
        ...translationState.survey,
        sciforms: newArray,
      },
    });
  };

  const setQuestionTitle = (e, groupIndex, groupSubIndex, groupSubItem) => {
    setTemplateUse(false);
    let newQuestionsArray = [];

    newQuestionsArray =
      translationState.survey.sciforms[0][translationLanguage]
        .sciquestionGroups[groupIndex].questions;

    const newArray = translationState.survey.sciforms;

    if (
      !translationState.survey.sciforms[0][translationLanguage]
        .sciquestionGroups[groupIndex].questions[groupSubIndex]
    ) {
      newQuestionsArray[groupSubIndex] = {
        id: groupSubItem.question,
        title: null,
        resultType: "TEXT",
        optionSetting: {
          id: null,
          scaleMin: "",
          scaleMax: "",
          scaleMinLabel: "",
          scaleMaxLabel: "",
          optionType: groupSubItem.option_type,
        },
        questionOptions: [],
      };
    } else {
      newQuestionsArray[groupSubIndex] = {
        ...newQuestionsArray[groupSubIndex],
        title: e.target.value,
        id: groupSubItem.question,
      };
    }

    newQuestionsArray[groupSubIndex] = {
      ...newQuestionsArray[groupSubIndex],
      title: e.target.value,
      id: groupSubItem.question,
    };

    const newQuestionGroupArray =
      translationState.survey.sciforms[0][translationLanguage]
        .sciquestionGroups;
    newQuestionGroupArray[groupIndex] = {
      ...newQuestionGroupArray[groupIndex],
      questions: newQuestionsArray,
    };

    newArray[0] = {
      ...newArray[0],
      [translationLanguage]: {
        ...newArray[0][translationLanguage],
        sciquestionGroups: newQuestionGroupArray,
      },
    };
    setTranslationState({
      ...translationState,
      survey: {
        ...translationState.survey,
        sciforms: newArray,
      },
    });
  };

  const setScaleTitle = (e, groupIndex, groupSubIndex, scale) => {
    const newArray = translationState.survey.sciforms;
    let newQuestionsArray = [];
    if (
      translationState.survey.sciforms[0][translationLanguage]
        .sciquestionGroups[groupIndex] !== undefined &&
      "questions" in
        translationState.survey.sciforms[0][translationLanguage]
          .sciquestionGroups[groupIndex]
    ) {
      newQuestionsArray =
        translationState.survey.sciforms[0][translationLanguage]
          .sciquestionGroups[groupIndex].questions;

      newQuestionsArray[groupSubIndex] = {
        ...newQuestionsArray[groupSubIndex],
        optionSetting: {
          ...newQuestionsArray[groupSubIndex].optionSetting,
          [scale]: e.target.value,
        },
      };
    } else {
      newQuestionsArray[groupSubIndex] = {
        optionSetting: {
          [scale]: e.target.value,
        },
      };
    }

    const newQuestionGroupArray =
      translationState.survey.sciforms[0][translationLanguage]
        .sciquestionGroups;
    newQuestionGroupArray[groupIndex] = {
      ...newQuestionGroupArray[groupIndex],
      questions: newQuestionsArray,
    };

    newArray[0] = {
      ...newArray[0],
      [translationLanguage]: {
        ...newArray[0][translationLanguage],
        sciquestionGroups: newQuestionGroupArray,
      },
    };
    setTranslationState({
      ...translationState,
      survey: {
        ...translationState.survey,
        sciforms: newArray,
      },
    });
  };

  const setRadioItemTitle = (
    e,
    groupIndex,
    groupSubIndex,
    radioIndex,
    radioItem
  ) => {
    const newArray = translationState.survey.sciforms;
    let newQuestionsOptionArray = [];
    newQuestionsOptionArray =
      translationState.survey.sciforms[0][translationLanguage]
        .sciquestionGroups[groupIndex].questions[groupSubIndex].questionOptions;

    if (
      !translationState.survey.sciforms[0][translationLanguage]
        .sciquestionGroups[groupIndex].questions[groupSubIndex].questionOptions[
        radioIndex
      ]
    ) {
      newQuestionsOptionArray[radioIndex] = {
        id: radioItem.id,
        optionType: radioItem.optionType,
        title: e.target.value,
      };
    } else {
      newQuestionsOptionArray[radioIndex] = {
        ...newQuestionsOptionArray[radioIndex],
        title: e.target.value,
      };
    }

    let questionOptionsArray = [];

    questionOptionsArray =
      translationState.survey.sciforms[0][translationLanguage]
        .sciquestionGroups[groupIndex].questions;
    questionOptionsArray[groupSubIndex].questionOptions =
      newQuestionsOptionArray;

    const newQuestionGroupArray =
      translationState.survey.sciforms[0][translationLanguage]
        .sciquestionGroups;

    newQuestionGroupArray[groupIndex] = {
      ...newQuestionGroupArray[groupIndex],
      questions: questionOptionsArray,
    };

    newArray[0] = {
      ...newArray[0],
      [translationLanguage]: {
        ...newArray[0][translationLanguage],
        sciquestionGroups: newQuestionGroupArray,
      },
    };

    setTranslationState({
      ...translationState,
      survey: {
        ...translationState.survey,
        sciforms: newArray,
      },
    });
  };

  const convertData = () => {

  }


  const handleTemplateChange = (e, id) => {
    //console.log("targett: ", e.target.value);
    setTemplateUse(true); 
    setTemplate(e.target.value);
    const fetchData = async () => {
      setLoading(true);
      try {

        const translationData = await axios(
          `${
            configData.SERVER_URL
          }/get/survey/?code=${e.target.value}&lang=${translationLanguage}&token=${localStorage.getItem(
            "token"
          )}`
        );

        if(translationData === undefined || translationData.length === 0 || null){
          //setFetchError("returned undefined/empty/null")
          console.log("returned undefined/empty/null")
        }
        if(translationData.data && translationData.data.result === "FAIL"){
          console.log("fail...")
        }

        
        
        //console.log("translation template data: ", translationData.data.data[0])
        setTemplateData(translationData.data.data[0])

        const convertedData = Object.values(translationData.data.data[0].data.pages).map((item,index) => {
          //console.log("item", item)
          const element = Object.values(item.groups)[0]
          //console.log("element: ", element)
          const questions = element.map((elementItem, elementIndex) => {
            //Object.keys(myObj)[0]
            //console.log("koklaus: ", Object.values(data[0].data.pages[index+1].groups)[0])
            return {
              //id: elementItem.question,
              id: Object.values(data[0].data.pages[index+1].groups)[0][elementIndex].question,
              title: elementItem.title,
              resultType: "TEXT",
              optionSetting: {
                id: null,
                scaleMinLabel: elementItem.scale_min_title,
                scaleMaxLabel: elementItem.scale_max_title,
                optionType: elementItem.option_type
              },
              questionOptions: elementItem.option_type === "RADIO" ? 
              //elementItem.questionOptions : []
              elementItem.questionOptions.map((optionItem, optionIndex) => (
                {
                  id: Object.values(data[0].data.pages[index+1].groups)[0][elementIndex].questionOptions[optionIndex].id,
                  title: optionItem.title,
                  value: optionItem.value,
                  optionType: optionItem.optionType
                }
              ))
              : []
            }
          })
          return {
            title: "", 
            //id: Object.keys(item.groups)[index],
            id: Object.values(data[0].data.pages[index+1].groups)[0].group,
            description: "",
            questions: questions
          }
          /*return {survey: {
            id: item.survey,
            title: item.survey_title,
            sciforms: [
              {
                [translationLanguage]: {
                  id: data[0].survey,
                  title: null,
                  sciquestionGroups: [],
                },
              },
            ],
          },
        }*/
        })

        //console.log("converted data: ", convertedData)
        setTranslationState({
          ...translationState,
          survey: {
            ...translationState.survey,
            sciforms: [ 
              {
                [translationLanguage]: {
                  ...translationState.survey.sciforms[translationLanguage],
                  id: data[0].form,
                  title: data[0].form_title,
                  sciquestionGroups: convertedData
                }
              }
          ]
          },
        });
        //console.log("ja translationstate näyttää tältä: ", translationState)
      } catch (error) {
        console.error('fetch error: ', error)
      }
      setLoading(false)
      
    }
    fetchData();
  };

//console.log("translationstate: ", translationState, "templatedata: ", templateData)

//console.log("DATA: ", data)

  return (
    <div>
      <Dialog fullWidth open={open}>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <DialogTitle>SurveyPreview</DialogTitle>
        <FormControl style={{margin: "5%"}}>
        <InputLabel>Pohja</InputLabel>
        <Select style={{width: "200px"}} value={template} onChange={handleTemplateChange}>
          {fetchData.map(item => (
            <MenuItem value={item.survey_code}>{item.survey_title}</MenuItem>
          ))}
        </Select>
          </FormControl>
        </div>
        
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1>Translation language: {translationLanguage}</h1>
              {data !== undefined && data.length > 0 && open && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  Survey title:{" "}
                  <TextField
                    multiline
                    value={
                      templateUse ? templateData.survey_title :
                      translationState.survey && 
                      translationState.survey.title !== null ? 
                      translationState.survey.title : data[0].survey_title
                    }
                    label={templateData.form_title ? "" : data[0].survey_title}
                    onChange={(e) => setSurveyTitle(e, data[0].survey)}
                  ></TextField>
                  Form title:{" "}
                  <TextField
                    multiline
                    value={translationState.survey && translationState.survey.sciforms && 
                      translationState.survey.sciforms[0][translationLanguage].title !== null ? 
                      translationState.survey.sciforms[0][translationLanguage].title :
                      templateData.form_title ? templateData.form_title : data[0].form_title}
                    label={templateData.form_title ? "" : data[0].form_title}
                    onChange={(e) => setFormTitle(e, data[0].form)}
                  ></TextField>
                  {Object.values(data[0].data.pages).map((item, itemIndex) => (
                    <div>
                      {Object.values(item.groups).map(
                        (groupItem, groupIndex) => (
                          <div
                            style={{
                              marginLeft: "5%",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            Group name:{" "}
                            <TextField
                            multiline
                              onChange={(e) =>
                                setQuestionGroupTitle(e, itemIndex, item)
                              }
                            />
                            Group description:{" "}
                            <TextField
                            multiline
                              onChange={(e) =>
                                setQuestionGroupDescription(e, itemIndex, item)
                              }
                            />
                            {groupItem.map(
                              (groupSubItem, groupSubItemIndex) => {
                                //console.log("groupIndex: ", groupIndex, "groupSubItemIndex: ", groupSubItemIndex)
                                return (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginLeft: "5%",
                                  }}
                                >
                                  Question title:{" "}
                                  <TextField
                                    disabled={
                                      !translationOpen &&
                                      (translationState === "" ||
                                        !translationState.survey.sciforms[0][
                                          translationLanguage
                                        ].sciquestionGroups[itemIndex] ||
                                        !translationState.survey.sciforms[0][
                                          translationLanguage
                                        ].sciquestionGroups[itemIndex]
                                          .questions)
                                    }
                                    onChange={(e) =>
                                      setQuestionTitle(
                                        e,
                                        itemIndex,
                                        groupSubItemIndex,
                                        groupSubItem
                                      )
                                    }
                                    value={
                                    templateUse && Object.keys(templateData).length > 0 && Object.hasOwn(templateData.data.pages[itemIndex+1].groups[Object.keys(templateData.data.pages[itemIndex+1].groups)[groupIndex]][groupSubItemIndex], "title") ? 
                                    templateData.data.pages[itemIndex+1].groups[Object.keys(templateData.data.pages[itemIndex+1].groups)[groupIndex]][groupSubItemIndex].title : 
                                    translationState && translationState.survey.sciforms[0][translationLanguage].sciquestionGroups[itemIndex] &&
                                    translationState.survey.sciforms[0][translationLanguage].sciquestionGroups[itemIndex].questions[groupSubItemIndex] ?
                                    translationState.survey.sciforms[0][translationLanguage].sciquestionGroups[itemIndex].questions[groupSubItemIndex].title : groupSubItem.title
                                    }
                                    
                                    multiline
                                    label={groupSubItem.title}
                                  ></TextField>
                                  {groupSubItem.option_type === "SELECT" && (
                                    <div style={{ marginLeft: "5%" }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        Scale max title:{" "}
                                        <TextField
                                          disabled={
                                            !translationOpen &&
                                            (translationState === "" ||
                                              !translationState.survey
                                                .sciforms[0][
                                                translationLanguage
                                              ].sciquestionGroups[itemIndex] ||
                                              !translationState.survey
                                                .sciforms[0][
                                                translationLanguage
                                              ].sciquestionGroups[itemIndex]
                                                .questions ||
                                              !translationState.survey
                                                .sciforms[0][
                                                translationLanguage
                                              ].sciquestionGroups[itemIndex]
                                                .questions[groupSubItemIndex])
                                          }
                                          onChange={(e) =>
                                            setScaleTitle(
                                              e,
                                              itemIndex,
                                              groupSubItemIndex,
                                              "scaleMaxLabel"
                                            )
                                          }
                                          multiline
                                          label={groupSubItem.scale_max_title}
                                          value={
                                            templateUse && Object.keys(templateData).length > 0 && Object.hasOwn(templateData.data.pages[itemIndex+1].groups[Object.keys(templateData.data.pages[itemIndex+1].groups)[groupIndex]][groupSubItemIndex], "scale_max_title") ?
                                            templateData.data.pages[itemIndex+1].groups[Object.keys(templateData.data.pages[itemIndex+1].groups)[groupIndex]][groupSubItemIndex].scale_max_title : 
                                           
                                            
                                            translationState && translationState.survey.sciforms[0][translationLanguage].sciquestionGroups[itemIndex] &&
                                            translationState.survey.sciforms[0][translationLanguage].sciquestionGroups[itemIndex].questions[groupSubItemIndex] &&
                                            translationState.survey.sciforms[0][translationLanguage].sciquestionGroups[itemIndex].questions[groupSubItemIndex].optionSetting.scaleMaxLabel
                                            }
                                        ></TextField>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        Scale min title:{" "}
                                        <TextField
                                          disabled={
                                            !translationOpen &&
                                            (translationState === "" ||
                                              !translationState.survey
                                                .sciforms[0][
                                                translationLanguage
                                              ].sciquestionGroups[itemIndex] ||
                                              !translationState.survey
                                                .sciforms[0][
                                                translationLanguage
                                              ].sciquestionGroups[itemIndex]
                                                .questions ||
                                              !translationState.survey
                                                .sciforms[0][
                                                translationLanguage
                                              ].sciquestionGroups[itemIndex]
                                                .questions[groupSubItemIndex])
                                          }
                                          onChange={(e) =>
                                            setScaleTitle(
                                              e,
                                              itemIndex,
                                              groupSubItemIndex,
                                              "scaleMinLabel"
                                            )
                                          }
                                          multiline
                                          label={groupSubItem.scale_min_title}
                                          value={
                                            templateUse && Object.keys(templateData).length > 0 && Object.hasOwn(templateData.data.pages[itemIndex+1].groups[Object.keys(templateData.data.pages[itemIndex+1].groups)[groupIndex]][groupSubItemIndex], "scale_min_title") ?
                                            templateData.data.pages[itemIndex+1].groups[Object.keys(templateData.data.pages[itemIndex+1].groups)[groupIndex]][groupSubItemIndex].scale_min_title : 
                                           
                                            
                                            translationState && translationState.survey.sciforms[0][translationLanguage].sciquestionGroups[itemIndex] &&
                                            translationState.survey.sciforms[0][translationLanguage].sciquestionGroups[itemIndex].questions[groupSubItemIndex] &&
                                            translationState.survey.sciforms[0][translationLanguage].sciquestionGroups[itemIndex].questions[groupSubItemIndex].optionSetting.scaleMinLabel
                                            }
                                        ></TextField>
                                      </div>
                                    </div>
                                  )}
                                  {groupSubItem.option_type === "RADIO" && (
                                    <div>
                                      {groupSubItem.questionOptions.map(
                                        (radioItem, radioIndex) => (
                                          <div style={{ marginLeft: "5%" }}>
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              Option {radioIndex} title:{" "}
                                              <TextField
                                                disabled={
                                                  !translationOpen &&
                                                  (translationState === "" ||
                                                    !translationState.survey
                                                      .sciforms[0][
                                                      translationLanguage
                                                    ].sciquestionGroups[
                                                      itemIndex
                                                    ] ||
                                                    !translationState.survey
                                                      .sciforms[0][
                                                      translationLanguage
                                                    ].sciquestionGroups[
                                                      itemIndex
                                                    ].questions ||
                                                    !translationState.survey
                                                      .sciforms[0][
                                                      translationLanguage
                                                    ].sciquestionGroups[
                                                      itemIndex
                                                    ].questions[
                                                      groupSubItemIndex
                                                    ] ||
                                                    !translationState.survey
                                                      .sciforms[0][
                                                      translationLanguage
                                                    ].sciquestionGroups[
                                                      itemIndex
                                                    ].questions[
                                                      groupSubItemIndex
                                                    ].questionOptions)
                                                }
                                                onChange={(e) =>
                                                  setRadioItemTitle(
                                                    e,
                                                    itemIndex,
                                                    groupSubItemIndex,
                                                    radioIndex,
                                                    radioItem
                                                  )
                                                }
                                                multiline
                                                label={radioItem.title}
                                                value={
                                                  Object.keys(translationState).length > 0 &&
                                                  translationState.survey
                                                      .sciforms[0][
                                                      translationLanguage
                                                    ].sciquestionGroups.length > 0 &&
                                                    translationState.survey
                                                    .sciforms[0][
                                                    translationLanguage
                                                  ].sciquestionGroups[
                                                    itemIndex
                                                  ] !== undefined &&
                                                    translationState.survey
                                                    .sciforms[0][
                                                    translationLanguage
                                                  ].sciquestionGroups[
                                                    itemIndex
                                                  ].hasOwnProperty("questions") &&
                                                    translationState.survey
                                                    .sciforms[0][
                                                    translationLanguage
                                                  ].sciquestionGroups[
                                                    itemIndex
                                                  ].questions.length > 0 &&
                                                  translationState.survey
                                                    .sciforms[0][
                                                    translationLanguage
                                                  ].sciquestionGroups[
                                                    itemIndex
                                                  ].questions[groupSubItemIndex] !== undefined &&
                                                  translationState.survey
                                                      .sciforms[0][
                                                      translationLanguage
                                                    ].sciquestionGroups[
                                                      itemIndex
                                                    ].questions[
                                                      groupSubItemIndex
                                                    ].hasOwnProperty("questionOptions") &&
                                                    translationState.survey
                                                    .sciforms[0][
                                                    translationLanguage
                                                  ].sciquestionGroups[
                                                    itemIndex
                                                  ].questions[
                                                    groupSubItemIndex
                                                  ].questionOptions[radioIndex] !== undefined ?
                                                   
                                                    translationState.survey
                                                    .sciforms[0][
                                                    translationLanguage
                                                  ].sciquestionGroups[
                                                    itemIndex
                                                  ].questions[
                                                    groupSubItemIndex
                                                  ].questionOptions[radioIndex].title : radioItem.title
                                                    /*translationState.survey
                                                      .sciforms[0][
                                                      translationLanguage
                                                    ].sciquestionGroups[
                                                      itemIndex
                                                    ].questions[
                                                      groupSubItemIndex
                                                    ].questionOptions.length > 0 ?
                                                    translationState.survey
                                                    .sciforms[0][
                                                    translationLanguage
                                                  ].sciquestionGroups[
                                                    itemIndex
                                                  ].questions[
                                                    groupSubItemIndex
                                                  ].questionOptions[radioIndex].title : radioItem.title*/
                                                  }
                                              ></TextField>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            )}
                          </div>
                        )
                      )}
                    </div>
                  ))}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: "2",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    alignSelf: "flex-end",
                    marginTop: "5%",
                    margin: "2%",
                  }}
                  color="error"
                  onClick={() => handleClose()}
                >
                  Peruuta
                </Button>
                <Button
                  variant="contained"
                  style={{
                    alignSelf: "flex-end",
                    marginTop: "5%",
                    margin: "2%",
                  }}
                  color="success"
                  onClick={() => sendData()}
                >
                  Lähetä
                </Button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        open={open && translationOpen}
        onClose={handleTranslationClose}
      >
        <DialogTitle>Valitse käännöskieli</DialogTitle>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Button
            style={{ marginTop: "5%", marginLeft: "15%", marginRight: "15%" }}
            variant="contained"
            onClick={() => handleTranslationClick("en_ENG")}
          >
            Englanti
          </Button>
          <Button
            style={{ marginTop: "5%", marginLeft: "15%", marginRight: "15%" }}
            variant="contained"
            onClick={() => handleTranslationClick("hu_HUN")}
          >
            Unkari
          </Button>
          <Button
            style={{
              marginTop: "5%",
              marginLeft: "15%",
              marginRight: "15%",
              marginBottom: "5%",
            }}
            variant="contained"
            onClick={() => handleTranslationClick("de_DEU")}
          >
            Saksa
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default SurveyTranslation;
