import { createSlice } from '@reduxjs/toolkit'

export const analyseSlice = createSlice({
  name: 'analyse',
  initialState: {
    value: {},
    groupIDs: [],
    translation: "fi_FIN"
  },
  reducers: {
    increment: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: state => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    setMyAnswers: (state, action) => {
      state.value = action.payload;
    },
    setGroupIDS: (state, action) => {
      state.groupIDs = action.payload;
    },
    setTranslationSlice: (state, action) => {
      state.translation = action.payload;
    },
  }
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount, setMyAnswers, setGroupIDS, setTranslationSlice } = analyseSlice.actions

export default analyseSlice.reducer