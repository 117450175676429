import React from 'react'
import ButtonAppBar from '../common/components/MaterialUI/components/AppBar'
import BasicRating from '../common/components/MaterialUI/components/Rating'
import SimpleBottomNavigation from '../common/components/MaterialUI/components/Footer'

const Main = () => {
return(
  <div style={{height: "100vh"}}>
    <ButtonAppBar />
    {/* <CustomRadioForm /> */}
    <BasicRating style={{minHeight: "89vh"}}/>
    {/*<Analyze />Ä/}
    {/*<Footer />*/}
    <SimpleBottomNavigation />
  </div>
)
}

export default Main
